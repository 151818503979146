import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonStyledProps, LinkStyledProps, ListStyledProps } from './interfaces'

export const ListStyled = styled.ul<ListStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  height: 100%;
  margin: 0;
  padding: 0;

  ${({ $isFully }) =>
    $isFully &&
    css`
      flex: 1 auto;
      margin-left: -7rem;
    `}
`

const selectedItemCss = css`
  border-bottom: ${({ theme }) => `2px solid ${theme.palette.primary.contrastText}`};
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  line-height: 2.5rem;
  display: inline-block;
`

const selectedItemFullCss = css`
  border-bottom: ${({ theme }) => `2px solid ${theme.palette.primary.main}`};
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.main};
  line-height: 2.5rem;
  display: inline-block;
`

export const LinkStyled = styled(Link)<LinkStyledProps>`
  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  cursor: pointer;
  margin-left: 2rem;
  padding: 0;

  &:hover {
    background-color: initial;
    color: ${({ theme }) => theme.palette.primary.light};
  }

  ${({ $isFully }) =>
    $isFully &&
    css`
      color: ${({ theme }) => theme.palette.colors.neutral[600]};

      &:hover {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    `}

  ${({ selected, $isFully }) =>
    selected && ($isFully ? selectedItemFullCss : selectedItemCss)}
`

export const ButtonStyled = styled.a<ButtonStyledProps>`
  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  cursor: pointer;
  margin-left: 2rem;
  padding: 0;

  &:hover {
    background-color: initial;
    color: ${({ theme }) => theme.palette.primary.light};
  }

  ${({ $isFully }) =>
    $isFully &&
    css`
      position: absolute;
      right: 5%;
      margin: ${({ theme }) => theme.spacing(-1, 2)};
      color: ${({ theme }) => theme.palette.colors.neutral[600]};

      &:hover {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    `}
`
