import { Advance } from 'interfaces/advance'
import { Product } from 'interfaces/creditLine'
import { Payment } from 'interfaces/loan'
import { RestApi } from '../../api/paketa'
import {
  CreateAdvanceRequest,
  GetAccountValidationRequest,
  GetAccountValidationResponse,
  SimulationResponse,
  GetSimulationsInstallmentResponse,
  GetListAnticipationResponse,
  AdvanceSimulationResponse
} from './interfaces'

const path = '/v1/credit-lines'

const CreditLines = {
  get() {
    return RestApi.get<Product[]>(`${path}/`)
  },
  createSimulation(data) {
    return RestApi.post<SimulationResponse>(`${path}/payroll/simulation`, data)
  },
  getAccountValidation(data: GetAccountValidationRequest) {
    return RestApi.post<GetAccountValidationResponse>(`${path}/account-validation`, data)
  },
  advanceSimulation() {
    return RestApi.get<AdvanceSimulationResponse>(`${path}/advance/simulation`)
  },
  getAdvance(id: string) {
    return RestApi.get<Advance>(`${path}/advance/${id}`)
  },
  createAdvance(data: CreateAdvanceRequest) {
    return RestApi.post<Advance>(`${path}/advance/`, data)
  },
  listAdvances() {
    return RestApi.get<Advance[]>(`${path}/advance/`)
  },
  getSimulationInstallment(simulationId?: string, selectedInstallmentId?: string) {
    return RestApi.get<GetSimulationsInstallmentResponse>(
      `${path}/simulations/${simulationId}/installments/${selectedInstallmentId}`
    )
  },
  getListAnticipation(params) {
    return RestApi.get<GetListAnticipationResponse>(
      `${path}/payroll/list-anticipation?loanId=${params.loanId}&paymentDate=${params.paymentDate}`
    )
  },
  getPayments(loanId: string) {
    return RestApi.get<Payment[]>(`${path}/payments/${loanId}`)
  }
}

export default CreditLines
