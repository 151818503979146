import React, { FC, memo, useEffect, useState } from 'react'
import { CNPJ as CNPJUtils, String as StringUtils } from '@base39/ui-utils'
import i18n from 'commons/i18n'
import { Autocomplete, Grid } from 'components'
import { Company } from 'layouts/CompanyPortal/interfaces'
import { ChooseCompanyProps } from './interfaces'
import { PaperStyled, TextFieldStyled } from './styles'

const ChooseCompany: FC<ChooseCompanyProps> = ({
  companies,
  selectedCompany,
  setSelectedCompany
}) => {
  const [autocompleteCompanies, setAutocompleteCompanies] = useState<Company[]>([])

  const handleAutoCompleteSelect = (_, newValue) => {
    newValue && setSelectedCompany(newValue)
  }

  const handleAutoCompleteChange = (_, inputValue) => {
    const newAutocompleteCompanies = companies.filter((company) => {
      const companyString = StringUtils.clear(`${company.cnpj}${company.tradeName}`)
      const inputString = StringUtils.clear(inputValue)
      return companyString?.includes(inputString)
    })
    setAutocompleteCompanies(newAutocompleteCompanies)
  }

  const renderAutocompleteTextField = (params) => (
    <TextFieldStyled
      {...params}
      variant="standard"
      label={i18n.t('employee.userImport.selectCNPJ')}
      InputProps={{
        ...params.InputProps,
        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
      }}
    />
  )

  useEffect(() => {
    setAutocompleteCompanies(companies)
  }, [companies])

  return (
    <Grid container direction="column" className="fullHeight">
      <Grid item className="fullWidth p-2" xs>
        <div className="mb-2">{i18n.t('employee.userImport.chooseCompanyText')}</div>
        <Autocomplete
          options={autocompleteCompanies}
          getOptionLabel={() =>
            `${CNPJUtils.format(selectedCompany.cnpj)} - ${selectedCompany.tradeName}`
          }
          onInputChange={handleAutoCompleteChange}
          onChange={handleAutoCompleteSelect}
          getOptionSelected={(option, value) =>
            option.id === value || selectedCompany.id === value
          }
          value={selectedCompany.id}
          renderOption={(item) => `${CNPJUtils.format(item.cnpj)} - ${item.tradeName}`}
          renderInput={renderAutocompleteTextField}
          PaperComponent={PaperStyled}
        />
      </Grid>
    </Grid>
  )
}

export default memo(ChooseCompany)
