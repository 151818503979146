import styled, { css } from 'styled-components'
import { getDefaultStyles } from '../../utils'
import { Typography } from '../../interfaces'

const defaultStyle = css`
  letter-spacing: 0;
`

const h1 = styled.h1<Typography>`
  ${(props) =>
    getDefaultStyles({
      defaultFont: 'SemiBold',
      defaultStyle,
      ...props
    })}

  font-size: 3rem;
  line-height: 3.625rem;
`

const h2 = styled.h2<Typography>`
  ${(props) =>
    getDefaultStyles({
      defaultFont: 'SemiBold',
      defaultStyle,
      ...props
    })}

  font-size: 2.25rem;
  line-height: 2.75rem;
`

const h3 = styled.h3<Typography>`
  ${(props) =>
    getDefaultStyles({
      defaultFont: 'SemiBold',
      defaultStyle,
      ...props
    })}

  font-size: 1.75rem;
  line-height: 2.125rem;
`

const h4 = styled.h4<Typography>`
  ${(props) =>
    getDefaultStyles({
      defaultFont: 'SemiBold',
      defaultStyle,
      ...props
    })}

  font-size: 1.5rem;
  line-height: 2.25rem;
`

const h5 = styled.h5<Typography>`
  ${(props) =>
    getDefaultStyles({
      defaultFont: 'SemiBold',
      defaultStyle,
      ...props
    })}

  font-size: 1.125rem;
  line-height: 1.375rem;
`

const h6 = styled.h6<Typography>`
  ${(props) =>
    getDefaultStyles({
      defaultFont: 'SemiBold',
      defaultStyle,
      ...props
    })}

  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
`

export default { h1, h2, h3, h4, h5, h6 }
