import { useStoreActions } from 'commons/hooks/useStore'
import CustomersApi from 'resources/services/customers'
import { User } from 'interfaces/user'
import { useQuery } from 'react-query'
import { useLogout } from 'commons/hooks'

export const useGetMe = (enabled = true) => {
  const logout = useLogout()
  const { setUser } = useStoreActions(({ user: userActions }) => ({
    setUser: userActions.setUser
  }))

  return useQuery<User>(['getMe', enabled], CustomersApi.get, {
    enabled,
    onSuccess: (data) => {
      setUser(data)
    },
    onError: (err) => {
      console.error('Error fetching user: ', err)
      logout()
    }
  })
}
