import { useQuery } from 'react-query'
import toast from 'commons/utils/toast'
import i18n from 'commons/i18n'
import loansApi from 'resources/services/loans'
import { useListLoansRequestParams } from './interfaces'

const useListLoansRequest = ({
  queryId,
  userCPF = '',
  userName = '',
  sort = '-requestDate',
  page = 1,
  pageSize = 50,
  status = '',
  companyId = '',
  onError: onErrorCb,
  onSuccess: onSuccessCb,
  enabled = true
}: useListLoansRequestParams) => {
  return useQuery(
    queryId,
    () =>
      loansApi.list({
        userCPF,
        userName,
        sort,
        page,
        pageSize,
        status,
        companyId
      }),
    {
      enabled,
      onError: (err) => {
        toast.error({ message: i18n.t('companyPortal.error.getListLoans') })
        if (onErrorCb) {
          onErrorCb(err)
        }
      },
      onSuccess: (res) => {
        if (onSuccessCb) {
          onSuccessCb(res)
        }
      }
    }
  )
}

export default useListLoansRequest
