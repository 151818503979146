import React from 'react'
import { CardProps } from './interfaces'
import { CardStyled } from './styles'

const Card = (
  { children, backgroundColor, onClick, padding = 1, ...props }: CardProps,
  ref
) => (
  <CardStyled
    onClick={onClick}
    $pointer={!!onClick}
    padding={padding}
    backgroundColor={backgroundColor}
    ref={ref}
    {...props}
  >
    {children}
  </CardStyled>
)

export default React.forwardRef(Card)
