import styled from 'styled-components'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

export const ContentStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`

export const ContainedIconStyled = styled(ArrowBackIcon)`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 1.75rem;
  padding: 5px;
  border-radius: 6px;
`
