import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'commons/hooks/useStore'
import { useLogout } from 'commons/hooks'
import { useCreditLinesRequest, useGetMe } from 'resources/hooks'
import { Loading } from 'components'
import { getAuthToken } from 'commons/utils/auth'
import { authPaths } from 'routes/auth'

const DataProvider = ({ children }) => {
  const creditLinesRequest = useCreditLinesRequest()
  const logout = useLogout()
  const history = useHistory()
  const pathname = history.location.pathname
  const { token } = getAuthToken()

  const { auth, payrollNoMaxOrMinCredit, advanceNoMaxOrMinCredit, user } = useStoreState(
    ({ user: userState, advance: advanceState, payroll: payrollState }) => ({
      auth: userState.auth,
      user: userState.user,
      payrollNoMaxOrMinCredit:
        !payrollState.data?.maxCredit || !payrollState.data?.minCredit,
      advanceNoMaxOrMinCredit:
        !advanceState.data?.maxCredit || !advanceState.data?.minCredit
    })
  )

  const { isAuthenticated } = auth

  const { setAuth } = useStoreActions(({ user: userActions }) => ({
    setAuth: userActions.setAuth,
    setUser: userActions.setUser
  }))

  const { isFetching: userIsFetching } = useGetMe(isAuthenticated && !user)

  useEffect(() => {
    if (!token && !authPaths.includes(pathname)) {
      logout()
    } else if (token && !isAuthenticated) {
      setAuth({
        isAuthenticated: true
      })
    }
  }, [token, isAuthenticated, logout, setAuth, pathname])

  const loading = useMemo(
    () =>
      creditLinesRequest?.isFetching ||
      userIsFetching ||
      (isAuthenticated &&
        (payrollNoMaxOrMinCredit || advanceNoMaxOrMinCredit) &&
        !creditLinesRequest?.data),
    [
      creditLinesRequest?.isFetching,
      userIsFetching,
      isAuthenticated,
      creditLinesRequest?.data,
      payrollNoMaxOrMinCredit,
      advanceNoMaxOrMinCredit
    ]
  )

  return loading ? <Loading /> : children
}

export default DataProvider
