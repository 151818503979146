import { lazy } from 'react'
import i18n from 'commons/i18n'
import { RouteProps } from 'routes/interfaces'
import simulation from './simulation'

const MinimalLayout = lazy(() => import('layouts/Minimal'))
const LoanDetail = lazy(() => import('containers/Products/Payroll/Loan/LoanDetail'))
const LoanPaymentHistoric = lazy(
  () => import('containers/Products/Payroll/Loan/PaymentHistoric')
)
const Historic = lazy(() => import('containers/Products/Payroll/Historic'))
const CCB = lazy(() => import('containers/Products/Payroll/Loan/CCB'))

const loanDetail: RouteProps[] = [
  {
    path: '/consignado/historico',
    layout: MinimalLayout,
    component: Historic,
    title: i18n.t('payroll.historic.title'),
    exact: true
  },
  {
    path: '/consignado/:id/pagamentos',
    layout: MinimalLayout,
    component: LoanPaymentHistoric,
    exact: true
  },
  {
    path: '/consignado/:id',
    layout: MinimalLayout,
    component: LoanDetail,
    exact: true
  },
  {
    path: '/consignado/:id/ccb',
    layout: MinimalLayout,
    component: CCB,
    exact: true
  }
]

export default [...simulation, ...loanDetail]
