import authRoutes from './auth'
import payrollRoutes from './payroll'
import mainRoutes from './main'
import privacyPolicyRoutes from './privacyPolicy'
import companyPortalRoutes from './companyPortal'
import advanceRoutes from './advance'
import { RouteProps } from './interfaces'

const employeeRoutes = [
  ...mainRoutes,
  ...payrollRoutes,
  ...advanceRoutes,
  ...privacyPolicyRoutes,
  ...authRoutes
] as RouteProps[]

const hrRoutes = [...companyPortalRoutes, ...employeeRoutes]

export default {
  hrRoutes,
  employeeRoutes
}
