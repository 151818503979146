import { Token } from 'interfaces/token'
import jwtDecode from 'jwt-decode'

export const setAuthToken = (token: string) => {
  if (token) {
    localStorage.setItem('auth0_bearer_token', token)
  }
}

export const getAuthToken = (): { token: string; decodedToken: Token } => {
  const authToken = localStorage.getItem('auth0_bearer_token')
  if (!authToken || authToken === 'undefined') {
    return {
      token: '',
      decodedToken: {}
    }
  }

  const decodedToken = jwtDecode<Token>(authToken)

  return {
    token: authToken,
    decodedToken
  }
}

export const getUserId = () => {
  const { decodedToken } = getAuthToken()

  const userId = decodedToken?.['https://api.paketa.io/claims/id']

  if (!userId) {
    return null
  }

  return userId
}

export const removeAuthToken = () => {
  localStorage.removeItem('auth0_bearer_token')
}
