import { createTheme } from '@material-ui/core/styles'
import defaultTheme from '@material-ui/core/styles/defaultTheme'
import { definedTheme } from 'white-label'

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 340,
      md: 768,
      lg: 1280,
      xl: 1920
    }
  },
  ...definedTheme.tenant,
  palette: definedTheme.palette,
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '4px'
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        }
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: 'Montserrat SemiBold',
        color: definedTheme.palette.colors.neutral[500],
        fontSize: defaultTheme.typography.fontSize,
        '&.Mui-disabled': {
          backgroundColor: definedTheme.palette.colors.neutral[50],
          color: definedTheme.palette.colors.neutral[300],
          WebkitTextFillColor: definedTheme.palette.colors.neutral[300]
        }
      },
      input: {
        '&::placeholder': {
          opacity: 1
        },
        '&[aria-invalid="true"]::placeholder': {
          color: definedTheme.palette.colors.error[400]
        }
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(34, 34, 34, 0.6)'
      }
    },
    MuiInputLabel: {
      root: {
        zIndex: '1',
        '&.Mui-disabled': {
          color: definedTheme.palette.colors.neutral[300]
        }
      }
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: '0',
          paddingTop: '16px'
        }
      },
      underline: {
        '&.Mui-disabled': {
          '&::before': {
            borderColor: definedTheme.palette.colors.neutral[300]
          }
        }
      }
    },
    MuiStepper: {
      root: {
        padding: '0'
      }
    }
  },
  typography: {
    fontFamily: 'Montserrat Medium'
  }
})
