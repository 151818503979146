import React, { FC, memo } from 'react'
import { Roles } from '@base39/ui-utils'
import { useStoreState } from 'commons/hooks/useStore'
import i18n from 'commons/i18n'
import { Feature } from 'components'
import { ListStyled, LinkStyled, ButtonStyled } from './styles'
import { NavLinksProps } from './interfaces'

const NavLinks: FC<NavLinksProps> = ({ onLogout, pathname, $isFully }) => {
  const { user } = useStoreState(({ user: userState }) => userState)

  return (
    <ListStyled $isFully={$isFully}>
      <Feature feature="enduser_menu_hr_home">
        {Roles.isRH(user) && (
          <li>
            <LinkStyled
              to={'/portal-empresa'}
              selected={pathname.includes('/portal-empresa')}
              $isFully={$isFully}
            >
              {i18n.t('header.companyPortal.title')}
            </LinkStyled>
          </li>
        )}
      </Feature>
      <li>
        <ButtonStyled onClick={onLogout} $isFully={$isFully}>
          {i18n.t('header.logout')}
        </ButtonStyled>
      </li>
    </ListStyled>
  )
}

export default memo(NavLinks)
