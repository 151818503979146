import { RestApi } from '../../api/paketa'
import {
  EmployeeResponse,
  GetPreSignedUrlBody,
  ImportErrorsResponse,
  Mappings,
  PreSignedUrlResponse
} from './interface'

export const path = '/v1/users/employments/import'

const EmployeesBase = {
  updateMapping(id: string, mapping: Mappings) {
    return RestApi.patch<Mappings>(`${path}/${id}`, mapping)
  },
  getById(id: string) {
    return RestApi.get<EmployeeResponse>(`${path}/${id}`)
  },
  getErrorsById(id: string, page: number, pageSize = 10) {
    return RestApi.get<ImportErrorsResponse>(`${path}/${id}/errors`, {
      page,
      pageSize
    })
  },
  getPreSignedUrl(body: GetPreSignedUrlBody) {
    return RestApi.post<PreSignedUrlResponse>(`${path}/presigned-url`, body)
  },
  sendObjectKey(objectKey: string) {
    return RestApi.post<EmployeeResponse>(`${path}`, { action: 'ADD', objectKey })
  },
  sendFileToPreSignedUrl(url: string, file, contentType: string) {
    return RestApi.put(url, file, {
      headers: {
        'Content-Type': contentType
      },
      removeAuthentication: true
    })
  }
}

export default EmployeesBase
