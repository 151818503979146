import React, { memo } from 'react'
import { FlatfileButton, ScalarDictionaryWithCustom } from '@flatfile/react'
import { useStoreState } from 'commons/hooks/useStore'
import { styleOverrides } from './styles'
import { i18nOverrides, validateFlatfileField } from './utils'
import { FlatfileProps } from './interfaces'

const Flatfile = ({
  fields,
  type,
  title,
  companies,
  onData,
  renderImporter
}: FlatfileProps) => {
  const userId = useStoreState(({ user }) => user.user?.id || '')

  const validateColumnData = (record: ScalarDictionaryWithCustom) => ({
    cnpj: validateFlatfileField('cnpj', record.cnpj, { companies }),
    cpf: validateFlatfileField('cpf', record.cpf),
    birthdayDate: validateFlatfileField('date', record.birthdayDate),
    hiredAt: validateFlatfileField('date', record.hiredAt),
    netSalary: validateFlatfileField('currencyNumber', record.netSalary),
    salary: validateFlatfileField('currencyNumber', record.salary),
    consignableMargin: validateFlatfileField('currencyNumber', record.consignableMargin),
    phoneNumber: validateFlatfileField('phone', record.phoneNumber)
  })

  return (
    <FlatfileButton
      licenseKey={process.env.REACT_APP_FLATFILE as string}
      customer={{ userId }}
      settings={{
        webhookUrl: process.env.REACT_APP_FLATFILE_WEBHOOK,
        type,
        fields,
        title,
        devMode: process.env.NODE_ENV !== 'production',
        disableManualInput: true,
        allowCustom: true,
        managed: true,
        i18nOverrides,
        styleOverrides,
        dateFormat: 'dd/mm/yyyy'
      }}
      onData={onData}
      onRecordInit={validateColumnData}
      onRecordChange={validateColumnData}
      render={renderImporter}
    />
  )
}

export default memo(Flatfile)
