import { LoanMe } from 'interfaces/loan'
import { BaseApi, RestApi } from '../../api/paketa'
import {
  GetLoanByIdResponse,
  CreateLoanResponse,
  SignRequest,
  SignResponse,
  AcceptCcbRequest,
  LoanListRequest,
  GetLoanListResponse,
  GetLoansCountResponse,
  SetLoanStatusRequest,
  CancelLoanRequest
} from './interfaces'

const path = '/v1/loans'

const Loans = {
  get() {
    return RestApi.get<LoanMe[]>(`${path}/me`)
  },
  getById(loanId: string) {
    return RestApi.get<GetLoanByIdResponse>(`${path}/${loanId}`)
  },
  create(data) {
    return RestApi.post<CreateLoanResponse>(`${path}/`, data)
  },
  sign(data: SignRequest) {
    return RestApi.post<SignResponse>('/v1/sign/', data)
  },
  acceptCcb(data: AcceptCcbRequest) {
    return BaseApi.put<boolean>(`/loans/${data.loan}/ccb/accept`, data)
  },
  list(params: LoanListRequest) {
    return RestApi.get<GetLoanListResponse>(`${path}/`, params)
  },
  getLoansCount() {
    return RestApi.get<GetLoansCountResponse>(`${path}/count`)
  },
  setLoanStatus(data: SetLoanStatusRequest) {
    return BaseApi.post<boolean>(`/loans/${data.id}/status`, data)
  },
  cancelLoan(data: CancelLoanRequest) {
    return RestApi.patch(`/v1-beta/loans/${data.id}/cancel`, { note: data.note })
  }
}

export default Loans
