import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { Button, CircularProgress } from 'components'
import i18n from 'commons/i18n'
import {
  Dialog,
  DialogHeader,
  DialogActions,
  DialogTitle,
  DialogContent
} from 'components/Dialog'
import Success from './Success'
import Loading from './Loading'
import ChooseCompany from './ChooseCompany'
import ImportOptions from './ImportOptions'
import CompaniesList from './CompaniesList'
import { FeedbackModalProps, OptionsType } from './interfaces'

const FeedbackModal: FC<FeedbackModalProps> = ({
  open,
  onClose,
  onConfirmImportRequest,
  situations,
  loading,
  feedbackModalSituation,
  companies,
  selectedCompany,
  setSelectedCompany,
  onCompanySubmitted,
  importedData,
  setModalSituationImport
}) => {
  const [option, setOption] = useState<OptionsType>({} as OptionsType)
  const isImportingUsers = useMemo(
    () => importedData.filter((obj) => obj.importStatus === 'loading').length > 0,
    [importedData]
  )

  const renderActionsButtonsSuccessOrChooseCompany = useCallback(
    (onClick, disabled) => (
      <Button variant="contained" color="primary" onClick={onClick} disabled={disabled}>
        {i18n.t('general.ok')}
      </Button>
    ),
    []
  )

  const renderActionsButtonsLoadingOrChooseOption = useCallback(
    () => (
      <>
        <Button variant="outlined" color="secondary" onClick={onClose} disabled={loading}>
          {i18n.t('general.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setModalSituationImport(option)}
          disabled={loading || !option}
        >
          {loading && <CircularProgress size={12} className="mr-1" />}
          {i18n.t('general.confirm')}
        </Button>
      </>
    ),
    [loading, option, onClose, setModalSituationImport]
  )

  const getActionsButtonsToImportWithManyCNPJs = useCallback(
    () => (
      <Button
        variant="contained"
        color="primary"
        onClick={onConfirmImportRequest}
        disabled={isImportingUsers}
      >
        {i18n.t(
          isImportingUsers || loading
            ? `form.loading`
            : importedData.filter((data) => data.importStatus === 'error').length > 0
            ? 'general.tryAgain'
            : 'general.confirm'
        )}
      </Button>
    ),
    [importedData, loading, onConfirmImportRequest, isImportingUsers]
  )

  const getCompaniesListContent = useCallback(
    () => <CompaniesList importedData={importedData} />,
    [importedData]
  )

  const getChooseCompanyContent = useCallback(
    () => (
      <ChooseCompany
        companies={companies}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />
    ),
    [companies, selectedCompany, setSelectedCompany]
  )

  const getImportOptionsContent = useCallback(
    () => (
      <ImportOptions
        selectedCompany={selectedCompany}
        situations={situations}
        option={option}
        setOption={setOption}
      />
    ),
    [selectedCompany, situations, option, setOption]
  )

  const getLoadingContent = useCallback(() => <Loading />, [])

  const getSuccessContent = useCallback(() => <Success />, [])

  const modalOptions = {
    importMultiple: {
      title: i18n.t('employee.userImport.importUsers'),
      content: getCompaniesListContent(),
      actions: getActionsButtonsToImportWithManyCNPJs()
    },
    chooseCompany: {
      title: i18n.t('employee.userImport.chooseCompany'),
      content: getChooseCompanyContent(),
      actions: renderActionsButtonsSuccessOrChooseCompany(onCompanySubmitted, loading)
    },
    chooseOption: {
      title: i18n.t('employee.userImport.chooseOption'),
      content: getImportOptionsContent(),
      actions: renderActionsButtonsLoadingOrChooseOption()
    },
    loading: {
      title: i18n.t('general.processing'),
      content: getLoadingContent(),
      actions: renderActionsButtonsLoadingOrChooseOption()
    },
    success: {
      title: i18n.t('general.success'),
      content: getSuccessContent(),
      actions: renderActionsButtonsSuccessOrChooseCompany(onClose, loading)
    }
  }

  return (
    <Dialog maxWidth="xs" fullWidth open={open} blockBackDropClick onClose={onClose}>
      <DialogHeader disabled={loading} onClose={onClose} />
      <DialogTitle>{modalOptions[feedbackModalSituation]['title']}</DialogTitle>
      <DialogContent>{modalOptions[feedbackModalSituation]['content']}</DialogContent>
      <DialogActions>{modalOptions[feedbackModalSituation]['actions']}</DialogActions>
    </Dialog>
  )
}

export default memo(FeedbackModal)
