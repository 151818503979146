import React, { FC } from 'react'
import { OptimizelyFeature } from '@optimizely/react-sdk'
import { FeatureProps } from './interfaces'

const Feature: FC<FeatureProps> = ({
  children,
  feature,
  hasVariable,
  inverse = false,
  onDeactivated
}) => {
  if (!feature) {
    return <>{children}</>
  }

  return (
    <OptimizelyFeature feature={feature}>
      {(isEnabled, variables) => {
        let show = isEnabled

        if (inverse) {
          show = !show
        }

        if (onDeactivated && !show) {
          onDeactivated()
        }

        return (
          show && (
            <>
              {hasVariable && children && children instanceof Function
                ? children(variables)
                : children}
            </>
          )
        )
      }}
    </OptimizelyFeature>
  )
}

export { withOptimizely } from '@optimizely/react-sdk'
export { Feature }
