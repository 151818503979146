import React, { FC, useEffect, useState } from 'react'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import Botd from '@fpjs-incubator/botd-agent'
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk'
import { Tenant } from '@base39/ui-utils'
import { useStoreState } from 'commons/hooks/useStore'
import ErrorBoundaryUI from 'components/ErrorBoundary'
import { theme } from 'providers/Theme'
import { datadogRum } from '@datadog/browser-rum'
import { FaviconProps } from './interfaces'

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY,
  eventBatchSize: 10,
  eventFlushInterval: 1000
})

const PaketaProvider: FC = ({ children }) => {
  const [isDatadogReady, setIsDatadogReady] = useState(false)
  const [isDatadogUserReady, setIsDatadogUserReady] = useState(false)
  const { user } = useStoreState(({ user: userState }) => userState)

  if (
    process.env.REACT_APP_DATADOG_RUM_APP_ID &&
    process.env.REACT_APP_DATADOG_RUM_TOKEN &&
    !isDatadogReady
  ) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_RUM_TOKEN,
      site: 'datadoghq.com',
      service: 'enduser',
      env: process.env.REACT_APP_ENV,
      version: process.env.REACT_APP_SHORT_SHA,
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      allowedTracingOrigins: [
        String(process.env.REACT_APP_API_URL),
        String(process.env.REACT_APP_SIGN_URL)
      ]
    })
    datadogRum.startSessionReplayRecording()
    datadogRum.onReady(() => {
      setIsDatadogReady(true)
    })
  }

  useEffect(() => {
    if (user && user?.id && !isDatadogUserReady) {
      const fpPromise = FingerprintJS.load({
        apiKey: process.env.REACT_APP_FINGERPRINT_JS_KEY as string,
        endpoint: 'https://fp.paketa.io'
      })

      const botdPromise = Botd.load({
        publicKey: process.env.REACT_APP_BOTD_PUBLIC
      })

      const loggedUser = {
        id: user?.id,
        name: user?.name,
        cpf: user?.cpf,
        company: user?.company,
        tenant: Tenant.getTenant()
      }

      botdPromise
        .then((botd) => botd.detect())
        .then((botdResult) => {
          fpPromise
            .then((fp) =>
              fp.get({
                linkedId: user?.id,
                tag: {
                  tenant: Tenant.getTenant(),
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  botd: botdResult.requestId
                }
              })
            )
            .then((fingerprintResult) => {
              datadogRum.setUser({
                ...loggedUser,
                fp: fingerprintResult.visitorId,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                botd: botdResult.requestId
              })
              setIsDatadogUserReady(true)
            })
            .catch((error) => {
              console.error('FingerprintJs Error: ', error)
              datadogRum.setUser({
                ...loggedUser,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                botd: botdResult.requestId
              })
            })
        })
        .catch((error) => {
          console.error('BOTD Error: ', error)
          datadogRum.setUser(loggedUser)
        })
    }
  }, [user, isDatadogUserReady])

  const applyFavIconByTenant = () => {
    const favicon: FaviconProps | null = document.getElementById('favicon')

    document.title = theme.title
    if (favicon) {
      favicon.href = theme.favicon
    }
  }

  useEffect(() => {
    applyFavIconByTenant()
  }, [])

  return (
    <ErrorBoundaryUI>
      <OptimizelyProvider
        optimizely={optimizely}
        user={{
          id: user?.id || null,
          attributes: {
            company: user?.company || null,
            cpf: user?.cpf,
            tenant: Tenant.getTenant(),
            application: process.env.REACT_APP_APPLICATION
          }
        }}
      >
        {children}
      </OptimizelyProvider>
    </ErrorBoundaryUI>
  )
}

export default PaketaProvider
