import { useCallback } from 'react'
import Event from 'third-party/event'
import { removeAuthToken } from 'commons/utils/auth'
import store, { useStoreActions } from './useStore'
import { useHistory } from 'react-router-dom'

const useLogout = () => {
  const history = useHistory()

  const { setAuth, clearUserData, clearLoanData, clearPayrollData, clearAdvanceData } =
    useStoreActions(
      ({
        advance: advanceActions,
        loan: loanActions,
        payroll: payrollActions,
        user: userActions
      }) => ({
        setAuth: userActions.setAuth,
        clearUserData: userActions.clearUserData,
        clearLoanData: loanActions.clearLoanData,
        clearPayrollData: payrollActions.clearPayrollData,
        clearAdvanceData: advanceActions.clearAdvanceData
      })
    )

  const logout = useCallback(() => {
    store.persist.clear()
    clearUserData()
    clearLoanData()
    clearPayrollData()
    clearAdvanceData()
    removeAuthToken()
    setAuth({
      isAuthenticated: false
    })
    history.replace('/')
    Event.track('AUTH_ENGAGE_CLICK_SIGNOUT')
  }, [setAuth, clearUserData, clearLoanData, clearPayrollData, clearAdvanceData, history])

  return logout
}

export default useLogout
