import React, { FC, useState } from 'react'
import { AsteriskProps } from './interfaces'

const SYMBOL = '_'

const Asterisk: FC<AsteriskProps> = ({ children, isTouched = false, total = 8 }) => {
  const totalAsterisk = SYMBOL.repeat(total)
  const [wasTouched, setWasTouched] = useState(isTouched)

  if (wasTouched) {
    return children
  }

  return (
    <>
      {React.cloneElement(children, {
        onFocus: () => setWasTouched(true),
        onBlur: () => setWasTouched(true),
        value: totalAsterisk,
        mask: totalAsterisk,
        type: 'password'
      })}
    </>
  )
}

export default Asterisk
