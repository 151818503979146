import { lazy } from 'react'
import { RouteProps } from 'routes/interfaces'

const PaketaAuth = lazy(() => import('containers/Auth/PaketaAuth'))

export const authPaths = ['/', '/reset-password']

const auth: RouteProps[] = [
  {
    path: authPaths,
    condition: 'OPEN',
    exact: true,
    component: PaketaAuth
  }
]

export default auth
