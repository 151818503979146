import styled, { css } from 'styled-components'
import { PageStyledProps } from './interfaces'

const getPadding = ({ hasNavbar }) => css`
  ${hasNavbar &&
  css`
    padding-top: 59px;

    @media (min-width: 0px) and (orientation: landscape) {
      padding-top: 51px;
    }

    @media (min-width: 600px) {
      padding-top: 67px;
    }
  `}
`

const PageStyled = styled.div<PageStyledProps>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  ${getPadding}
  background: ${({ background }) => background || 'inherit'};
`

const PageScrollStyled = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export { PageStyled, PageScrollStyled }
