import React from 'react'
import i18n from 'commons/i18n'
import { Close as CloseIcon } from '@material-ui/icons'
import { InputProps } from '@material-ui/core'
import { withField } from '../Field'
import { InputSearchProps } from './interfaces'
import {
  IconButtonStyled,
  InputSearchStyled,
  LoupeIconStyled,
  ProgressIconStyled
} from './styles'

const InputSearch = ({
  id,
  name,
  disabled,
  loading,
  value,
  onChange,
  ...props
}: InputSearchProps) => {
  return (
    <InputSearchStyled
      fullWidth
      id={id}
      name={name}
      disabled={disabled}
      value={value}
      onChange={onChange as InputProps['onChange']}
      placeholder={i18n.t('general.search')}
      endAdornment={
        loading ? (
          <ProgressIconStyled />
        ) : (
          !!value &&
          !disabled && (
            <IconButtonStyled
              onClick={() => onChange({ target: { id, name, value: '' } })}
              aria-label={i18n.t('general.clear')}
            >
              <CloseIcon fontSize="small" />
            </IconButtonStyled>
          )
        )
      }
      startAdornment={
        <LoupeIconStyled
          disabled={disabled}
          className="mr-1"
          width="20px"
          height="20px"
        />
      }
      {...props}
    />
  )
}

export { InputSearch }
export default withField(InputSearch)
