import React, { FC, memo } from 'react'
import { Typography } from 'components'
import { Money as MoneyUtils } from '@base39/ui-utils'
import { PriceProps } from './interfaces'
import { PriceStyled } from './styles'

const Price: FC<PriceProps> = ({ value, color, className }) => {
  const numberValue = typeof value === 'string' ? parseInt(value) : value
  const money = MoneyUtils.formatCurrency(numberValue || 0).split(',')
  const unity = money[0]
  const cents = `,${money[1]}`

  return (
    <PriceStyled className={className}>
      <Typography variant="h2" font="SemiBold" color={color}>
        {unity}
      </Typography>
      <Typography variant="body3" font="SemiBold" color={color}>
        {cents}
      </Typography>
    </PriceStyled>
  )
}

export default memo(Price)
