import React, { FC } from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { AccordionProps } from './interfaces'
import {
  AccordionStyled,
  AccordionSummaryStyled,
  AccordionDetailsStyled,
  AccordionContentStyled,
  AccordionTitleStyled
} from './styles'

const Accordion: FC<AccordionProps> = ({ title, onChange, expanded, children }) => {
  return (
    <AccordionStyled square expanded={expanded} onChange={onChange}>
      <AccordionSummaryStyled expandIcon={<ChevronRightIcon />}>
        <AccordionTitleStyled variant="body2" font="Medium">
          {title}
        </AccordionTitleStyled>
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <AccordionContentStyled>{children}</AccordionContentStyled>
      </AccordionDetailsStyled>
    </AccordionStyled>
  )
}

export default Accordion
