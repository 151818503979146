import React from 'react'
import { ReactComponent as ActivityIcon } from 'assets/svg/activity.svg'
import { Typography } from 'components'
import { theme } from 'providers/Theme'
import { EmptyProps } from './interfaces'
import { EmptyStyled, IconWrapperStyled, ContainerStyled } from './styles'

const Empty = ({
  title,
  icon,
  variant = 'default',
  iconShadow,
  svgWidth,
  containerHeight = 45,
  svgHeight,
  ...props
}: EmptyProps) => {
  return (
    <EmptyStyled variant={variant} containerHeight={containerHeight} {...props}>
      <ContainerStyled>
        <IconWrapperStyled
          className="mb-1"
          iconShadow={iconShadow}
          svgWidth={svgWidth}
          svgHeight={svgHeight}
        >
          {icon ? icon : <ActivityIcon />}
        </IconWrapperStyled>
        <Typography
          color={
            variant === 'card'
              ? theme.palette.colors.neutral[500]
              : theme.palette.colors.neutral[400]
          }
          variant="body3"
          font="Regular"
        >
          {title}
        </Typography>
      </ContainerStyled>
    </EmptyStyled>
  )
}

export default Empty
