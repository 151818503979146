import React, { FC } from 'react'
import FormHelperText from '../FormHelperText'
import i18n from 'commons/i18n'
import { AlertHelperTextProps } from './interfaces'

const AlertHelperText: FC<AlertHelperTextProps> = ({ value, max }) => {
  const size = value.length
  const error = size > max

  return (
    <FormHelperText error={error}>
      {i18n.t('form.alertMaxText', { value: size, max })}
    </FormHelperText>
  )
}

export default AlertHelperText
