import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

export const ToastContainerStyled = styled(ToastContainer)`
  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.palette.error.main};
  }

  .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.palette.warning.main};

    path {
      fill: ${({ theme }) => theme.palette.colors.neutral[500]} !important;
    }
  }

  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.palette.success.main};
  }

  .Toastify__toast--info {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  .toast-position {
    top: ${({ theme }) => theme.spacing(1)}px;
  }

  .Toastify__toast {
    border-radius: ${({ theme }) => theme.spacing(2)}px;
    padding: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    min-height: ${({ theme }) => theme.spacing(1)}px;
    font-family: 'Montserrat Regular';
    font-size: 0.875rem;
  }

  .Toastify__toast-body {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex: 1;
  }
`
