import styled from 'styled-components'

export const ComingSoonStyled = styled.div`
  position: relative;
`

export const SoonMessageStyled = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 1.5rem;
  background: ${({ theme }) => theme.palette.colors.neutral[500]};
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
`
