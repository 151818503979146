import { lazy } from 'react'
import { RouteProps } from 'routes/interfaces'

const LeadLayout = lazy(() => import('layouts/Lead'))
const PrivacyPolicy = lazy(() => import('white-label/PrivacyPolicy'))
const SCRTermsContainer = lazy(() => import('containers/Policies/SCRTerms'))

const privacyPolicy: RouteProps[] = [
  {
    path: ['/privacidade'],
    layout: LeadLayout,
    component: PrivacyPolicy,
    condition: 'OPEN',
    exact: true
  },
  {
    path: ['/termos-scr'],
    layout: LeadLayout,
    component: SCRTermsContainer,
    condition: 'OPEN',
    exact: true
  }
]

export default privacyPolicy
