import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDecision } from '@optimizely/react-sdk'
import { getAuthToken, getUserId } from 'commons/utils/auth'
import { useLogout } from 'commons/hooks'
import { useStoreState } from 'commons/hooks/useStore'
import { Tenant } from '@base39/ui-utils'
import { useGetMe } from 'resources/hooks'
import SayCheeseApi from 'resources/services/say-cheese'
import { Loading } from 'components'

const SayCheese = ({ children }) => {
  const { token, decodedToken } = getAuthToken()
  const userId = getUserId() || ''
  const logout = useLogout()
  const faceRecognitionToken = decodedToken?.face_recognition
  const [decision] = useDecision('enduser_biometric', {
    autoUpdate: true
  })

  const faceDuplicated = useStoreState(
    ({ user: userState }) => userState.user?.faceDuplicated
  )

  const { isFetching: isFetchingGetMe } = useGetMe()

  const { data: biometricData, isFetching } = useQuery(
    ['enrollment-3d', userId],
    () => SayCheeseApi.get(userId),
    {
      enabled: decision.enabled && !faceRecognitionToken,
      staleTime: 0
    }
  )

  const redirectToBiometric =
    decision.enabled && !biometricData?.registered && !isFetching && !faceRecognitionToken

  useEffect(() => {
    if (redirectToBiometric && token) {
      const tenant = Tenant.getTenant()
      const apiUrl = {
        abc: process.env.REACT_APP_BIOMETRICS_URL_ABC,
        paketa: process.env.REACT_APP_BIOMETRICS_URL_PAKETA
      }

      window.location.replace(
        `${apiUrl[tenant]}/?session_token=${token}&redirect_by=${window.location.href}`
      )
    }
  }, [isFetching, redirectToBiometric, token])

  useEffect(() => {
    if (faceDuplicated) {
      logout()
    }
  }, [faceDuplicated, logout])

  if (isFetching || isFetchingGetMe) {
    return <Loading />
  }

  return children
}

export default SayCheese
