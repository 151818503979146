import styled, { css } from 'styled-components'

export const ContentStyled = styled.div`
  cursor: pointer;
  width: 100%;
  min-height: 36px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-left: 2px solid ${({ color, theme }) => color || theme.palette.primary.main};
  padding: 1px 0 0 16px;
  ${({ onClick }) =>
    onClick &&
    css`
      :hover {
        cursor: pointer;
      }
    `}
`

export const DateStyled = styled.div`
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.palette.colors.neutral[400]};
`

export const LeftContentStyled = styled.div`
  text-align: left;
`

export const RightContentStyled = styled.div`
  text-align: right;
`

export const BlockStyled = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.palette.colors.neutral[300]};
  }
`

export const ArrowRightIconStyled = styled.img`
  margin: 0 32px;
`
