import React from 'react'
import i18n from 'commons/i18n'
import { InputSearch } from 'components/Form/InputSearch'
import { FiltersProps } from './interfaces'
import { Autocomplete, Grid } from 'components'
import { PaperStyled, PopperStyled, ProgressIconStyled, TextFieldStyled } from './styles'

const Filters = ({
  className,
  loading,
  onSearchChange,
  search,
  placeholder,
  companies,
  hasData,
  company,
  onInputChangeCompany,
  onAutoCompleteSelectCompany,
  status,
  statusValue,
  onInputChangeStatus,
  onAutoCompleteSelectStatus
}: FiltersProps) => {
  const hasCompaniesFilter = companies ? !!company : false
  const hasStatusFilter = status ? !!statusValue : false
  const noFilter = !search && !hasCompaniesFilter && !hasStatusFilter
  const disabled = noFilter && !hasData

  const renderAutocompleteTextField = (params, placeholder) => (
    <TextFieldStyled
      {...params}
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        placeholder,
        endAdornment: (
          <React.Fragment>
            {loading ? <ProgressIconStyled /> : params.InputProps.endAdornment}
          </React.Fragment>
        )
      }}
    />
  )

  return (
    <Grid container spacing={2} className={className}>
      <Grid item xs>
        <InputSearch
          disabled={disabled}
          loading={loading}
          onChange={onSearchChange}
          value={search}
          placeholder={placeholder}
        />
      </Grid>
      {!!companies && (
        <Grid item xs={4}>
          <Autocomplete
            disabled={disabled}
            options={companies}
            loading={loading}
            getOptionLabel={(item) => item.tradeName || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            onInputChange={onInputChangeCompany}
            onChange={onAutoCompleteSelectCompany}
            value={company}
            renderOption={(item) => item.tradeName}
            renderInput={(params) =>
              renderAutocompleteTextField(params, i18n.t('general.companies'))
            }
            PaperComponent={PaperStyled}
            PopperComponent={PopperStyled}
          />
        </Grid>
      )}
      {!!status && (
        <Grid item xs={4}>
          <Autocomplete
            disabled={disabled}
            options={status}
            loading={loading}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(item) => i18n.t(item.label)}
            renderOption={(item) => i18n.t(item.label)}
            onChange={onAutoCompleteSelectStatus}
            onInputChange={onInputChangeStatus}
            value={statusValue}
            renderInput={(params) =>
              renderAutocompleteTextField(params, i18n.t('general.status'))
            }
            PaperComponent={PaperStyled}
            PopperComponent={PopperStyled}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default Filters
