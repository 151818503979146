import styled from 'styled-components'

export const SectionCardStyled = styled.div`
  background: ${({ theme }) => theme.palette.colors.neutral[50]};
  padding: 24px 0;
  display: flex;
`

export const ContentStyled = styled.div`
  color: ${({ theme, color }) => color && theme.palette[color].main};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  border-left: 2px solid ${({ theme, color }) => color && theme.palette[color].main};
  width: 100%;
`

export const ImageStyled = styled.img`
  max-height: 150px;
  margin-bottom: 8px;
`

export const TitleStyled = styled.div`
  color: ${({ theme, color }) =>
    color ? theme.palette[color].main : theme.palette.colors.neutral[600]};
`
