import { gql } from '@apollo/client'

export const IMPORT_USERS_COMPANY = gql`
  mutation MUTATION_IMPORT_USERS_COMPANY(
    $company: String!
    $users: [JSON]!
    $option: ImportOptions!
  ) {
    importUsersCompany(option: $option, body: { company: $company, users: $users }) {
      id
      lastImportedAt
    }
  }
`

export const GET_SITUATIONS_IMPORT_USERS = gql`
  query QUERY_GET_SITUATIONS_IMPORT_USERS($company: String!, $users: [JSON]!) {
    getSituationsToImportUsers(body: { company: $company, users: $users }) {
      add
      remove
      found
      totalSheet
    }
  }
`
