import React, { FC } from 'react'
import { TypographyProps } from './interfaces'
import texts from './sections'

const Typography: FC<TypographyProps> = ({ children, variant, ...props }) => {
  const Text = texts[variant]

  return <Text {...props}>{children}</Text>
}

export default Typography
