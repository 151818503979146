import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { MessageStyledProps } from './interfaces'

export const ImageStyled = styled.img`
  padding: ${({ theme }) => theme.spacing(0, 6.25)};
  width: 100%;
`

export const MessageStyled = styled.div<MessageStyledProps>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ statusColor }) => statusColor};
  border-radius: ${({ theme }) => theme.spacing(0.625)}px;
  margin-top: ${({ theme }) => theme.spacing(1.25)}px;
  padding: ${({ theme }) => theme.spacing(1.25)}px;
`

export const TextFieldStyled = styled(TextField)`
  width: 100%;
`

export const TextStyled = styled.p`
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
`

export const PaperStyled = styled.div`
  width: auto;
  overflow-y: scroll;
  max-height: ${({ theme }) => theme.spacing(62.5)}px;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0;
  background-color: ${({ theme }) => theme.palette.colors.neutral[50]};
  line-height: 1.5rem;
  .MuiAutocomplete-listbox::-webkit-scrollbar,
  ::-webkit-scrollbar {
    display: none;
  }
`

export const StatusStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${({ theme }) => theme.spacing(0, 3.5, 0, 2.25)};
  position: relative;
  svg {
    color: ${({ color }) => color};
  }
  & > label {
    margin: 0;
    max-height: ${({ theme }) => theme.spacing(3)}px;
    & > span {
      padding: 0;
    }
  }
`
