import { createStore, createTypedHooks, persist } from 'easy-peasy'
import { advance, payroll, loan, user, companyPortal } from 'models'

export interface StoreModel {
  advance: typeof advance
  payroll: typeof payroll
  loan: typeof loan
  user: typeof user
  companyPortal: typeof companyPortal
}

const typedHooks = createTypedHooks<StoreModel>()

const store = createStore<StoreModel>({
  advance,
  payroll,
  loan: persist(loan),
  user: persist(user),
  companyPortal
})

export const useStoreActions = typedHooks.useStoreActions
export const useStoreState = typedHooks.useStoreState

export default store
