import styled from 'styled-components'
import Typography from 'components/Typography'

export const ActionsStyled = styled.div`
  margin-left: auto;
`

export const BackButtonStyled = styled.button`
  border: none;
  display: flex;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: ${({ theme }) => theme.spacing(0.5)}px;
  background-color: ${({ theme }) => theme.palette.colors.neutral[50]};
`

export const WrapperStyled = styled.div`
  display: flex;
`

export const TypographyStyled = styled(Typography)`
  flex: 1;
`
