import ptBR from './pt-br'
import ptABC from './abc'
import { Object as ObjectUtils } from '@base39/ui-utils'

const mapLocale = {
  default: ptBR,
  abc: ptABC
}

const url = window.location.hostname

const locale = url.includes('abcbrasil.com.br')
  ? ObjectUtils.deepMerge(mapLocale.default, mapLocale.abc)
  : mapLocale.default

export { locale }
