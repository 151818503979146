import { Typography } from 'components'
import React, { FC } from 'react'
import { TagProps } from './interfaces'
import { TagStyled } from './styles'

const Tag: FC<TagProps> = ({
  borderRadius,
  children,
  color,
  variant = 'contained',
  ...props
}) => {
  return (
    <TagStyled borderRadius={borderRadius} color={color} variant={variant} {...props}>
      <Typography font="Medium" variant="body4" color={color}>
        {children}
      </Typography>
    </TagStyled>
  )
}

export default Tag
