import styled, { css } from 'styled-components'
import { GetSizesProps } from './interfaces'

const getSizes = ({ fullWidth = false, fullHeight = false }) => css<GetSizesProps>`
  ${fullWidth &&
  `
    max-width: 100% !important;
  `}

  ${fullHeight &&
  `
    height: 100%;
  `}
`

const PageContentStyled = styled.div`
  flex: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: ${({ theme }) => theme.spacing(3)}px;
  position: relative;

  ${getSizes}

  ${({ theme, noPadding = false }) => css`
    ${noPadding &&
    `
      padding: 0;
    `}

    ${theme.breakpoints.up('lg')} {
      max-width: 66.666667%;
    }

    ${theme.breakpoints.up('md')} {
      max-width: 50%;
    }
  `}

  ${({ minHeight = false }) =>
    minHeight &&
    css`
      min-height: 0;
    `};
`

export default PageContentStyled
