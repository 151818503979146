import TenantError from 'assets/svg/error_tenant.svg'
import DeadGhost from 'assets/svg/error_dead-ghost.svg'
import Ghost from 'assets/png/ghost.png'
import PixKey from 'assets/svg/pix-key.svg'
import i18n from 'commons/i18n'
import { MappedErrorObject } from 'components/ErrorBoundary/interfaces'

const errors: { [key: string]: MappedErrorObject } = {
  DEFAULT: {
    image: DeadGhost,
    message: i18n.t('errorBoundary.errors.DEFAULT.message')
  },
  TENANT_NOT_FOUND: {
    image: TenantError,
    message: i18n.t('errorBoundary.errors.TENANT_NOT_FOUND.message')
  },
  PAGE_NOT_FOUND: {
    image: Ghost,
    message: i18n.t('errorBoundary.errors.PAGE_NOT_FOUND.message')
  },
  PIX_NO_ACCOUNT: {
    image: PixKey,
    title: i18n.t('errorBoundary.errors.PIX_NO_ACCOUNT.title'),
    message: i18n.t('errorBoundary.errors.PIX_NO_ACCOUNT.message')
  },
  INTERNAL_SCHEDULED_MAINTENANCE: {
    image: DeadGhost,
    message: i18n.t('errorBoundary.errors.INTERNAL_SCHEDULED_MAINTENANCE.message')
  }
}

export default errors
