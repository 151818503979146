import React, { FC } from 'react'
import { ContrastDataProps } from './interfaces'
import { ContrastDataStyled } from './styles'

const ContrastData: FC<ContrastDataProps> = ({
  borderRadius = 0.25,
  padding = 1,
  children,
  backgroundColor,
  className,
  onClick
}) => {
  return (
    <ContrastDataStyled
      data-testid="contrast-data"
      borderRadius={borderRadius}
      padding={padding}
      backgroundColor={backgroundColor}
      className={className}
      onClick={onClick}
    >
      {children}
    </ContrastDataStyled>
  )
}

export default ContrastData
