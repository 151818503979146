import React, { memo, useState } from 'react'
import i18n from 'commons/i18n'
import { Button, Flatfile } from 'components'
import {
  ImportUsersProps,
  ImportedDataProps,
  FeedbackModalSituation,
  Situation
} from './interfaces'
import { useLazyQuery, useMutation } from '@apollo/client'
import toast from 'commons/utils/toast'
import { IMPORT_USERS_COMPANY, GET_SITUATIONS_IMPORT_USERS } from './graphql'
import { Company } from 'layouts/CompanyPortal/interfaces'
import { FeedbackModal, InstructionsModal } from './sections'
import {
  canFinishImport,
  formatDataToImportUsers,
  formatUsersToImport,
  formatResponseMessageAndStatus
} from './utils'
import { fileFields } from './importOptions'

const ImportUsers = ({ companies = [], loading }: ImportUsersProps) => {
  const [selectedCompany, setSelectedCompany] = useState<Company>({} as Company)
  const [importedData, setImportedData] = useState<ImportedDataProps[]>([])
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false)
  const [openInstructionsModal, setOpenInstructionsModal] = useState(false)
  const [situations, setSituations] = useState<Situation>({
    add: 0,
    replace: 0,
    remove: 0
  })
  const [feedbackModalSituation, setFeedbackModalSituation] =
    useState<FeedbackModalSituation>('importMultiple')

  const closeModal = () => {
    setOpenFeedbackModal((prevState) => !prevState)
    setFeedbackModalSituation('importMultiple')
  }

  const [getSituations, { loading: situationsLoading }] = useLazyQuery(
    GET_SITUATIONS_IMPORT_USERS,
    {
      onCompleted: ({ getSituationsToImportUsers }) => {
        setSituations(getSituationsToImportUsers)
      },
      onError: () => {
        toast.error({ message: i18n.t('employee.userImport.error') })
      },
      fetchPolicy: 'network-only'
    }
  )

  const updateStatusFromImportRequest = ({ id, status, message }) => {
    setImportedData((prevState) => {
      return prevState.map((data) => {
        const newData = data
        if (newData.id === id) {
          newData.importStatus = status
          if (message) {
            newData.message = message
          }
        }
        return newData
      })
    })
  }

  const [importUsers, { loading: importUsersCompanyLoading }] = useMutation(
    IMPORT_USERS_COMPANY,
    {
      onCompleted: (prop) => {
        updateStatusFromImportRequest({
          id: prop.importUsersCompany.id,
          status: 'success',
          message: null
        })
      }
    }
  )

  const requestToImportUsers = (data) => {
    if (data.importStatus === 'loading') {
      const { id, importOption, users } = data || {}

      importUsers({
        variables: {
          users,
          company: id,
          option: importOption
        }
      }).catch((err) => {
        const { message, status } = formatResponseMessageAndStatus(err.message)
        updateStatusFromImportRequest({ id, status, message })
      })
    }
  }

  const onConfirmImportRequest = () => {
    if (canFinishImport(importedData)) {
      setFeedbackModalSituation('success')
      return
    }

    const loadingImportedData = formatDataToImportUsers(importedData)

    setImportedData(loadingImportedData)
    loadingImportedData.forEach(requestToImportUsers)
  }

  const getOptionsByUser = ({ users, importOption, importStatus }) => {
    const newImportedData = {
      id: selectedCompany.id,
      cnpj: selectedCompany.cnpj,
      tradeName: selectedCompany.tradeName,
      importOption,
      importStatus,
      users
    }

    setImportedData([newImportedData])
    getSituations({
      variables: {
        company: selectedCompany.id,
        users
      }
    })
    setFeedbackModalSituation('chooseOption')
  }

  const getImportOptions = () => {
    getOptionsByUser({
      users: importedData[0].users,
      importOption: importedData[0].importOption,
      importStatus: importedData[0].importStatus
    })
  }

  const onFlatFileResponse = async (response) => {
    const { data, $importer } = await response
    const hasCnpj = !!data[0].cnpj

    const formattedData = formatUsersToImport({
      companies: hasCnpj ? companies : [],
      users: data
    })

    setFeedbackModalSituation(hasCnpj ? 'importMultiple' : 'chooseCompany')

    setImportedData(formattedData)
    $importer.close()
    setOpenFeedbackModal(true)
  }

  const renderFlatfile = () =>
    companies.length && !openFeedbackModal && openInstructionsModal ? (
      <Flatfile
        fields={fileFields}
        title={i18n.t('employee.userImport.title')}
        type={'User'}
        companies={companies}
        onData={onFlatFileResponse}
        renderImporter={(_, launch) => {
          return (
            <Button
              variant="contained"
              id="flatfile-button-importer"
              color="primary"
              onClick={launch}
              disabled={loading}
              width="270"
              className="none"
            />
          )
        }}
      />
    ) : null

  return (
    <>
      {renderFlatfile()}
      <InstructionsModal
        isOpen={openInstructionsModal}
        onClose={() => setOpenInstructionsModal(false)}
        disabled={loading}
        onImportClick={() => {
          const triggerImportButton = document.getElementById('flatfile-button-importer')

          if (triggerImportButton) {
            triggerImportButton.click()
            setOpenInstructionsModal(false)
          } else {
            console.error(i18n.t('companyPortal.error.updateBase'))
          }
        }}
      />
      <FeedbackModal
        open={openFeedbackModal}
        situations={{
          data: situations,
          situationsLoading
        }}
        importedData={importedData}
        onClose={closeModal}
        onConfirmImportRequest={onConfirmImportRequest}
        loading={importUsersCompanyLoading}
        feedbackModalSituation={feedbackModalSituation}
        companies={companies}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
        onCompanySubmitted={getImportOptions}
        setModalSituationImport={(option: string) => {
          setFeedbackModalSituation('importMultiple')
          setImportedData(([prevState]) => {
            return [
              {
                ...prevState,
                importOption: option
              }
            ]
          })
        }}
      />
      <Button
        onClick={() => setOpenInstructionsModal(true)}
        disabled={loading}
        id="open-instructions-modal-button"
        variant="text"
        className="none"
      />
    </>
  )
}

export default memo(ImportUsers)
