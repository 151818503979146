import approval from './approval.json'
import landing from './landing.json'
import proposal from './proposal.json'
import seo from './seo.json'
import signup from './signup.json'

export default {
  approval,
  landing,
  proposal,
  seo,
  signup
}
