import styled, { css } from 'styled-components'
import { HeaderProps } from './interfaces'

export const HeaderContainerStyled = styled.div<HeaderProps>`
  padding: ${({ theme }) => theme.spacing(3)}px 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: -1px;

  ${({ $isFully }) =>
    $isFully &&
    css`
      background-color: ${({ theme }) => theme.palette.primary.contrastText};
    `}
`

export const HeaderContentStyled = styled.header<HeaderProps>`
  width: 100%;
  max-width: 768px;
  margin: 0 auto;

  ${({ $isFully }) =>
    $isFully &&
    css`
      max-width: none !important;
      margin-bottom: -0.5rem;
    `}
`

export const WrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing(2)}px;
`

export const LogoStyled = styled.img`
  max-width: 5.625rem;
  height: 2.375rem;
  cursor: pointer;
  object-fit: contain;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 3.75rem;
      height: 1.75rem;
    }
  `}
`

export const Submenu = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  background: ${({ theme }) => theme.palette.colors.neutral[50]};
  width: 100%;
  margin-bottom: -1.25rem;
  border: 1px solid ${({ theme }) => theme.palette.colors.neutral[200]};
  border-radius: 0.188rem;
  margin-top: 0.5rem;
`
