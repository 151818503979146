import React, { FC } from 'react'
import { Radio, RadioGroup as MuiRadioGroup, FormControlLabel } from '@material-ui/core'
import { withField } from '../Field'
import { RadioGroupProps } from './interfaces'

const RadioGroup: FC<RadioGroupProps> = ({ options, ...props }) => (
  <MuiRadioGroup {...props}>
    {options.map(({ label, value, color = 'default' }, index) => (
      <FormControlLabel
        key={`item-${index}`}
        value={value}
        control={<Radio color={color} />}
        label={label}
      />
    ))}
  </MuiRadioGroup>
)

export { RadioGroup }
export default withField(RadioGroup)
