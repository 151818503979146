import { lazy } from 'react'
import { RouteProps } from 'routes/interfaces'

const MinimalLayout = lazy(() => import('layouts/Minimal'))
const Proposal = lazy(() => import('containers/Products/Payroll/Engine/Proposal'))
const SelectInstallments = lazy(
  () => import('containers/Products/Payroll/Engine/SelectInstallments')
)
const DocumentUpload = lazy(
  () => import('containers/Products/Payroll/Engine/DocumentUpload')
)
const CompleteProfile = lazy(
  () => import('containers/Products/Payroll/Engine/CompleteProfile')
)
const CompleteProfileAddress = lazy(
  () => import('containers/Products/Payroll/Engine/CompleteProfileAddress')
)
const CompleteFinancial = lazy(
  () => import('containers/Products/Payroll/Engine/CompleteFinancial')
)

const path = '/consignado/simulador'

const simulation: RouteProps[] = [
  {
    path: `${path}`,
    layout: MinimalLayout,
    component: Proposal,
    exact: true,
    title: 'signup.step6.chooseValue'
  },
  {
    path: `${path}/selecionar-parcelas`,
    layout: MinimalLayout,
    component: SelectInstallments,
    exact: true,
    title: 'signup.stepSelectInstallment.title'
  },
  {
    path: `${path}/documentos`,
    layout: MinimalLayout,
    component: DocumentUpload,
    exact: true,
    title: 'signup.stepDocumentUpload.title'
  },
  {
    path: `${path}/dados-pessoais`,
    layout: MinimalLayout,
    component: CompleteProfile,
    exact: true,
    title: 'signup.stepPersonalData.title'
  },
  {
    path: `${path}/endereco`,
    layout: MinimalLayout,
    component: CompleteProfileAddress,
    exact: true,
    title: 'signup.stepPersonalAddress.title'
  },
  {
    path: `${path}/dados-bancarios`,
    layout: MinimalLayout,
    component: CompleteFinancial,
    exact: true,
    title: 'signup.financial.title'
  }
]

export default simulation
