import styled from 'styled-components'
import MuiInput from '@material-ui/core/Input'

export const MuiInputStyled = styled(MuiInput)`
  input {
    -moz-appearance: textfield;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`
