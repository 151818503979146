import styled, { css } from 'styled-components'
import { Autocomplete } from '@material-ui/lab'
import { AutocompleteStyledProps } from './interfaces'

export const AutocompleteStyled = styled(Autocomplete)<AutocompleteStyledProps>`
  ${({ $neutral }) =>
    $neutral &&
    css`
      border-radius: ${({ theme }) => theme.spacing(1)}px;
      background-color: ${({ theme }) => theme.palette.colors.neutral[50]};

      && .MuiFormHelperText-root {
        display: contents;
      }

      && .MuiAutocomplete-endAdornment {
        top: inherit;
      }
    `}
`

export const PopperStyled = styled.div`
  position: absolute;
  z-index: 9;
  overflow: none;
`

export const PaperStyled = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.colors.neutral[50]};
  padding: ${({ theme }) => theme.spacing(1, 0, 1, 1)}px;
  max-height: ${({ theme }) => theme.spacing(128)}px;

  .MuiAutocomplete-listbox {
    ::-webkit-scrollbar {
      width: ${({ theme }) => theme.spacing(1.25)}px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: ${({ theme }) => theme.spacing(0.75)}px;
      background-color: ${({ theme }) => theme.palette.colors.neutral[200]};
    }
  }

  .MuiAutocomplete-groupLabel {
    background-color: ${({ theme }) => theme.palette.colors.neutral[50]};
    color: ${({ theme }) => theme.palette.colors.neutral[500]};
    font-size: 1rem;
    font-family: 'Montserrat Bold';
    padding: ${({ theme }) => theme.spacing(0, 1)};
  }

  .MuiAutocomplete-option {
    color: ${({ theme }) => theme.palette.colors.neutral[500]};
    font-size: 0.8125rem;
    font-family: 'Montserrat SemiBold';
    padding: ${({ theme }) => theme.spacing(1.5, 0, 1.5, 1)};
  }
`
