import i18n from 'commons/i18n'

const ERROR_TYPES = {
  youCanOnlyUploadFileTypes: i18n.t('payroll.uploadDocument.error.invalidFormat'),
  exceedsSize: i18n.t('payroll.uploadDocument.error.exceedsSize'),
  mimeTypeDoesNotMatch: i18n.t('payroll.uploadDocument.error.mimeTypeDoesNotMatch')
}

export const defaultOptions = {
  autoProceed: true,
  debug: process.env.NODE_ENV !== 'production',
  locale: {
    strings: ERROR_TYPES
  }
}
