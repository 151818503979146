import { Signature } from '@base39/ui-utils'
import { useState, useEffect } from 'react'
import { BrowserData } from 'interfaces/general'

const useBrowserData = () => {
  const [browserData, setBrowserData] = useState<BrowserData>({
    fingerprint: undefined,
    location: undefined
  })

  useEffect(() => {
    const signature = new Signature()

    signature
      .getId()
      .then((result) =>
        setBrowserData((prevState) => ({ ...prevState, fingerprint: result }))
      )
      .catch((err) => console.error('getId', err))

    signature
      .getGeolocation()
      .then((result) =>
        setBrowserData((prevState) => ({ ...prevState, location: result }))
      )
      .catch((err) => console.log('getGeolocation denied', err))
  }, [])

  return browserData
}

export default useBrowserData
