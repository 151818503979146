import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { DialogTitle as DialogTitleComponent } from '@material-ui/core'
import { DialogTitleProps } from './interfaces'

const DialogTitleWrapper: FC<DialogTitleProps> = (props) => (
  <DialogTitleComponent className="pt-0 pb-2 mb-1" {...props} />
)

const DialogTitle = styled(DialogTitleWrapper)<DialogTitleProps>`
  ${({ theme }) => `
    padding: ${theme.spacing(1, 3)};
  `}

  border-bottom: 2px solid ${({ theme }) => theme.palette.colors.neutral[100]};

  ${({ $noBorder }) =>
    $noBorder &&
    css`
      border: none;
    `}

  h2 {
    color: ${({ theme }) => theme.palette.colors.neutral[500]};
    font-size: 1.5rem;
    font-family: 'Montserrat SemiBold';
    text-align: center;
    line-height: 36px;
  }
`

export default DialogTitle
