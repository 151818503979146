import { lazy } from 'react'
import { RouteProps } from 'routes/interfaces'

const MainLayout = lazy(() => import('layouts/Main'))
const MinimalLayout = lazy(() => import('layouts/Minimal'))
const Products = lazy(() => import('containers/Home'))
const Professional = lazy(() => import('containers/Professional'))

const portal: RouteProps[] = [
  {
    path: '/produtos',
    layout: MainLayout,
    component: Products,
    exact: true
  },
  {
    path: '/profissional',
    layout: MinimalLayout,
    component: Professional,
    exact: true
  }
]

export default portal
