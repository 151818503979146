import { useQuery } from 'react-query'
import toast from 'commons/utils/toast'
import i18n from 'commons/i18n'
import CreditLinesApi from 'resources/services/credit-lines'
import { GetSimulationsInstallmentResponse } from 'resources/services/credit-lines/interfaces'

export const useGetSimulationInstallmentRequest = (
  simulateId?: string,
  selectedInstallment?: string
) => {
  return useQuery<GetSimulationsInstallmentResponse>(
    ['getSimulationsInstallment', simulateId, selectedInstallment],
    () => CreditLinesApi.getSimulationInstallment(simulateId, selectedInstallment),
    {
      enabled: !!simulateId && !!selectedInstallment,
      onError: () => toast.error({ message: i18n.t('general.toast.emptyError') })
    }
  )
}
