import React from 'react'
import { render } from 'react-dom'
import App from './App'

// if (process.env.REACT_APP_ENABLE_MOCKS === 'true') {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }

render(<App />, document.getElementById('root'))
