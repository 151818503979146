import styled from 'styled-components'
import { getDefaultStyles } from '../../utils'
import { Typography } from '../../interfaces'

const body1 = styled.div<Typography>`
  ${(props) =>
    getDefaultStyles({
      defaultFont: 'Regular',
      ...props
    })}

  font-size: 1.125rem;
  line-height: 1.375rem;
`

const body2 = styled.div<Typography>`
  ${(props) =>
    getDefaultStyles({
      defaultFont: 'Regular',
      ...props
    })}

  font-size: 1rem;
  line-height: 1.1875rem;
`

const body3 = styled.div<Typography>`
  ${(props) =>
    getDefaultStyles({
      defaultFont: 'Medium',
      ...props
    })}

  font-size: 0.875rem;
  line-height: 1.125rem;
`

const body4 = styled.div<Typography>`
  ${(props) =>
    getDefaultStyles({
      defaultFont: 'Regular',
      ...props
    })}

  font-size: 0.75rem;
  line-height: 0.9375rem;
`

const body5 = styled.div<Typography>`
  ${(props) =>
    getDefaultStyles({
      defaultFont: 'Regular',
      ...props
    })}

  font-size: 0.625rem;
  line-height: 0.813rem;
`

export default { body1, body2, body3, body4, body5 }
