import styled from 'styled-components'
import { WrapperStyledProps } from './interfaces'

export const WrapperStyled = styled.div<WrapperStyledProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  path {
    fill: ${({ theme, type }) =>
      type === 'warn'
        ? theme.palette.colors.neutral[500]
        : theme.palette.primary.contrastText} !important;
  }
`
