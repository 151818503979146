import { BaseApi } from '../../api/paketa'
import { UppyPostRequest, UppyPostResponse } from './interfaces'

const path = '/uppy/s3'

const Uppy = {
  post(data: UppyPostRequest) {
    return BaseApi.post<UppyPostResponse>(`${path}`, data)
  }
}

export default Uppy
