import { Action, action } from 'easy-peasy'
import { SimulationLoan } from 'interfaces/loan'

export interface LoanModel {
  simulationLoan: SimulationLoan | null
  setSimulationLoan: Action<LoanModel, SimulationLoan | null>
  clearLoanData: Action<LoanModel>
  loanSignedId: string
  setLoanSigned: Action<LoanModel, string>
}

const loan: LoanModel = {
  simulationLoan: null,
  loanSignedId: '',
  setSimulationLoan: action((state, payload) => {
    state.simulationLoan = payload
  }),
  setLoanSigned: action((state, payload) => {
    state.loanSignedId = payload
  }),
  clearLoanData: action((state) => {
    state.simulationLoan = null
  })
}

export default loan
