import styled, { css } from 'styled-components'
import { CardItemProps } from './interfaces'
import { ReactComponent as ArrowRightIcon } from 'assets/svg/arrow-right.svg'

export const CardItemStyled = styled.div<CardItemProps>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0.5, 1, 0.5, 3)};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  position: relative;
  align-items: center;

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;
    `}

  ${({ color, theme }) =>
    color &&
    css`
      &::before {
        content: '';
        background-color: ${color};
        border-radius: ${theme.spacing(2.5)}px;
        width: ${theme.spacing(0.5)}px;
        height: 80%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    `}
`

export const RightSideStyled = styled.div`
  text-align: right;
  margin-left: auto;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`

export const SectionStyled = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ArrowRightIconStyled = styled(ArrowRightIcon)`
  right: 8px;
  position: absolute;
`
