import { Action, action } from 'easy-peasy'
import { Company } from 'interfaces/companyPortal'
import { LoanList } from 'resources/services/loans/interfaces'
import { Date as DateUtils } from '@base39/ui-utils'

export interface CompanyPortalModel {
  canApproveCompanies?: Company[]
  inAnalysisLoans?: LoanList[]
  outdatedCompanies: Company[]
  approvedLoans: string[]
  hasDisplayedMenu: boolean
  setCanApproveCompanies: Action<CompanyPortalModel, Company[]>
  setInAnalysisLoans: Action<CompanyPortalModel, LoanList[]>
  setApprovedLoans: Action<CompanyPortalModel, string[]>
  setHasDisplayedMenu: Action<CompanyPortalModel, boolean>
}

const companyPortal: CompanyPortalModel = {
  canApproveCompanies: undefined,
  inAnalysisLoans: undefined,
  outdatedCompanies: [],
  approvedLoans: [],
  hasDisplayedMenu: false,
  setCanApproveCompanies: action((state, payload) => {
    const intervalDays = -5
    const outdatedBaseCompanies = payload?.filter((company) => {
      if (company.lastImportedAt !== null) {
        const interval = DateUtils.dateDiff(
          DateUtils.formatDate(company.lastImportedAt, 'MM/DD/yyyy'),
          DateUtils.getNow().format('MM/DD/yyyy'),
          'days'
        )
        return interval <= intervalDays
      }
    })
    state.outdatedCompanies = outdatedBaseCompanies
    state.canApproveCompanies = payload
  }),
  setInAnalysisLoans: action((state, payload) => {
    state.inAnalysisLoans = payload
  }),
  setApprovedLoans: action((state, payload) => {
    state.approvedLoans = [...state.approvedLoans, ...payload]
  }),
  setHasDisplayedMenu: action((state, payload) => {
    state.hasDisplayedMenu = payload
  })
}

export default companyPortal
