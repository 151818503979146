import React, { FC } from 'react'
import { Typography } from 'components'
import { ContentStyled, ImageStyled, SectionCardStyled, TitleStyled } from './style'
import { SectionCardProps } from './interfaces'

const SectionCard: FC<SectionCardProps> = ({ icon, title, color, image, children }) => {
  return (
    <SectionCardStyled>
      <ContentStyled color={color}>
        {icon}
        <Typography variant="body2" className="mb-1" font="Bold">
          <TitleStyled color={color}>{title}</TitleStyled>
        </Typography>
        <ImageStyled src={image} alt={title} />
        {children}
      </ContentStyled>
    </SectionCardStyled>
  )
}

export default SectionCard
