import styled, { css } from 'styled-components'
import { TableBody, TableRow, TableCell, Table, TableHead } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { SimpleTableStyledProps } from './interfaces'

const TableCellStyled = styled(TableCell).attrs(({ align }) => ({
  align
}))`
  padding: ${({ theme }) => theme.spacing(2, 1)};
`

const TableBodyStyled = styled(TableBody)`
  background: ${({ theme }) => theme.palette.colors.neutral[0]};
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
`

const TableHeadStyled = styled(TableHead)`
  th.MuiTableCell-stickyHeader {
    background: ${({ theme }) => theme.palette.colors.neutral[50]};
    font-weight: 600;
    padding: ${({ theme }) => theme.spacing(1, 1)};
  }
`
const TableRowBodyStyled = styled(TableRow)`
  :nth-child(even) td {
    background-color: ${({ theme }) => theme.palette.colors.neutral[50]};
  }
`

const PaperWrapTableStyled = styled(Paper)`
  width: 100%;
  overflow-x: auto;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      width: 100%;
      margin: 0 auto;
    }
  `}

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar:hover {
    box-shadow: inset 0 0 1px 0 ${({ theme }) => theme.palette.colors.neutral[900]};
  }
  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette.colors.neutral[500]};
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`

const TableContainerStyled = styled(Table).attrs(({ width, padding }) => ({
  width,
  padding,
  stickyHeader: true
}))`
  width: ${({ width }) => width};
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;

  thead th {
    white-space: nowrap;
  }

  tbody td {
    word-break: 'break-word';
  }
`

const SimpleTableStyled = styled(Table)<SimpleTableStyledProps>`
  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth};
    `}
`

export {
  TableBodyStyled,
  TableRowBodyStyled,
  PaperWrapTableStyled,
  TableContainerStyled,
  TableCellStyled,
  TableHeadStyled,
  SimpleTableStyled
}
