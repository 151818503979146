import i18n from 'commons/i18n'
import toast from 'commons/utils/toast'

export const ERROR_ACTIONS = {
  500: () => toast.error({ message: i18n.t('general.errorUnknown') }),
  UNKNOWN: (error?: any) =>
    toast.error({
      message: i18n.t([
        `apiErrors.${error?.response?.data?.code}`,
        'general.errorUnknown'
      ])
    })
}

export const onResponseError = (error) => {
  const status = error?.response?.status
  const apiInterceptors = error?.config?.interceptors
  const action =
    apiInterceptors?.[status] ?? (`ERROR_ACTIONS`[status] || ERROR_ACTIONS.UNKNOWN)

  if (action) {
    action(error)
  }

  return Promise.reject(error)
}
