import { RestApi } from '../../api/paketa'
import { GetEnrollment } from './interfaces'

const path = 'v1/biometrics/'

const SayCheese = {
  get(userId: string) {
    return RestApi.get<GetEnrollment>(`${path}enrollment/${userId}`)
  }
}

export default SayCheese
