import React, { FC } from 'react'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import CheckIcon from '@material-ui/icons/Check'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { theme } from 'providers/Theme'
import { FlatCard } from 'components'
import { MessageStyled, StatusStyled } from './styles'
import { CompaniesListProps } from './interfaces'

const CompaniesList: FC<CompaniesListProps> = ({ importedData }) => {
  const getStatusColor = (importStatus) => {
    const elements = {
      loading: theme.palette.primary.main,
      success: theme.palette.success.main,
      error: theme.palette.error.main
    }
    return elements[importStatus] ?? theme.palette.warning.main
  }

  const getStatusIcon = (importStatus, statusColor) => {
    const elements = {
      loading: <MoreHorizIcon />,
      success: <CheckIcon />,
      error: <HighlightOffIcon />,
      default: <div />
    }
    return <StatusStyled color={statusColor}>{elements[importStatus]}</StatusStyled>
  }

  const getMessages = (message, statusColor) => {
    return <MessageStyled statusColor={statusColor}>{message}</MessageStyled>
  }

  return (
    <>
      {importedData.map(({ id, importStatus, cnpj, tradeName, users, message }) => {
        return (
          <div key={id} className="pt-2 pb-2">
            <FlatCard
              color={getStatusColor(importStatus)}
              leftSide={{
                title: cnpj,
                info: tradeName,
                label: `Importar ${users.length} funcionários`
              }}
            >
              {getStatusIcon(importStatus, getStatusColor(importStatus))}
            </FlatCard>
            {message && getMessages(message, getStatusColor(importStatus))}
          </div>
        )
      })}
    </>
  )
}

export default CompaniesList
