import { useQuery } from 'react-query'
import toast from 'commons/utils/toast'
import i18n from 'commons/i18n'
import LoansApi from 'resources/services/loans'

export const useGetLoanById = (id: string) => {
  return useQuery(['loan', id], () => LoansApi.getById(id), {
    enabled: !!id,
    onError: () => toast.error({ message: i18n.t('general.toast.emptyError') })
  })
}
