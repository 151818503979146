import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Divider, Typography } from 'components'
import { ReactComponent as ArrowBack } from 'assets/svg/arrow-back.svg'
import { theme } from 'providers/Theme'
import {
  ActionsStyled,
  BackButtonStyled,
  WrapperStyled,
  TypographyStyled
} from './styles'
import { PageHeaderProps, PageHeaderComponents } from './interface'

const PageHeader: FC<PageHeaderProps> & PageHeaderComponents = ({
  children,
  divider,
  backButton,
  backRoute
}) => {
  const history = useHistory()
  return (
    <>
      {backButton && (
        <BackButtonStyled
          className="mb-1"
          onClick={() => {
            backRoute ? history.push(backRoute) : history.goBack()
          }}
        >
          <ArrowBack />
        </BackButtonStyled>
      )}
      {children}
      {divider && <Divider className="mb-3" />}
    </>
  )
}

const Title: FC = ({ children }) => (
  <TypographyStyled
    variant="h4"
    font="Medium"
    className="mb-1 mr-1"
    color={theme.palette.colors.neutral[500]}
  >
    {children}
  </TypographyStyled>
)

const Subtitle: FC = ({ children }) => (
  <Typography
    variant="body3"
    font="SemiBold"
    className="mb-1"
    color={theme.palette.colors.neutral[500]}
  >
    {children}
  </Typography>
)

const Content: FC = ({ children }) => <div className="mb-3">{children}</div>

const Actions: FC = ({ children }) => <ActionsStyled>{children}</ActionsStyled>

const Wrapper: FC = ({ children }) => <WrapperStyled>{children}</WrapperStyled>

PageHeader.Title = Title
PageHeader.Subtitle = Subtitle
PageHeader.Content = Content
PageHeader.Actions = Actions
PageHeader.Wrapper = Wrapper

export default PageHeader
