import styled from 'styled-components'
import { ReactComponent as LoupeIcon } from 'assets/svg/loupe.svg'
import { Input } from '../Input'
import CircularProgress from '../../CircularProgress'
import IconButton from '../../IconButton'
import { LoupeIconStyledProps } from './interfaces'

export const ProgressIconStyled = styled(CircularProgress).attrs({
  size: 17
})`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  left: calc(100% - 34px);
  top: calc(50% - 9px);
`

export const IconButtonStyled = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(0.5)}px;
`

export const InputSearchStyled = styled(Input)`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.colors.neutral[50]};
  padding: ${({ theme }) => theme.spacing(0, 1)};
  height: ${({ theme }) => theme.spacing(5)}px;

  :not(.Mui-disabled) {
    :hover,
    :focus-within {
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
      outline: 1px solid ${({ theme }) => theme.palette.colors.neutral[100]};
    }
  }

  input {
    font-size: 0.875rem;
  }

  ::after {
    content: none;
  }

  ::before {
    content: none;
  }
`

export const LoupeIconStyled = styled(LoupeIcon)<LoupeIconStyledProps>`
  min-width: ${({ theme }) => theme.spacing(2.5)}px;
  path {
    fill: ${({ theme, disabled }) => disabled && theme.palette.text.disabled};
  }
`
