import React, { Fragment } from 'react'
import { StoreProvider } from 'easy-peasy'
import { Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import CssBaseline from '@material-ui/core/CssBaseline'
import 'react-toastify/dist/ReactToastify.css'
import { Router as RouterUtils } from '@base39/ui-utils'
import GlobalStyle from 'commons/styles/global-style'
import store from 'commons/hooks/useStore'
import Main from './containers/Main'
import { PaketaProvider, DataProvider, ThemeProvider, SayCheeseProvider } from 'providers'
import './commons/i18n'
import client from './resources/api/graphql'
import { tenant } from 'white-label'
import reactQueryClient from './resources/api/reactQuery'

const addGtm = tenant === 'paketa' && process.env.REACT_APP_ENV === 'prod'

const script = `<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TBQKCQ2');
<!-- End Google Tag Manager -->`

const App = () => (
  <ApolloProvider client={client}>
    {addGtm && (
      <Helmet>
        <script>{script}</script>
      </Helmet>
    )}
    <StoreProvider store={store}>
      <QueryClientProvider client={reactQueryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider>
          <Fragment>
            <CssBaseline />
            <GlobalStyle />
            <PaketaProvider>
              <Router history={RouterUtils.history}>
                <DataProvider>
                  <SayCheeseProvider>
                    <Main />
                  </SayCheeseProvider>
                </DataProvider>
              </Router>
            </PaketaProvider>
          </Fragment>
        </ThemeProvider>
      </QueryClientProvider>
    </StoreProvider>
  </ApolloProvider>
)

export default App
