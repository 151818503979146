import { Action, action } from 'easy-peasy'
import { PixData } from 'interfaces/advance'
import { Product } from 'interfaces/creditLine'
export interface AdvanceModel {
  data: Product | null
  pixData: PixData | null
  advanceValue: number
  isNewAdvance: boolean
  setData: Action<AdvanceModel, Product | null>
  setPixData: Action<AdvanceModel, PixData | null>
  setAdvanceValue: Action<AdvanceModel, number>
  setIsNewAdvance: Action<AdvanceModel, boolean>
  clearAdvanceData: Action<AdvanceModel>
}

const advance: AdvanceModel = {
  data: null,
  pixData: null,
  advanceValue: 0,
  isNewAdvance: false,
  setData: action((state, payload) => {
    state.data = payload
  }),
  setPixData: action((state, payload) => {
    state.pixData = payload
  }),
  setAdvanceValue: action((state, payload) => {
    state.advanceValue = payload
  }),
  setIsNewAdvance: action((state, payload) => {
    state.isNewAdvance = payload
  }),
  clearAdvanceData: action((state) => {
    state.data = null
    state.pixData = null
    state.advanceValue = 0
  })
}

export default advance
