import { useQuery, UseQueryOptions } from 'react-query'
import CompaniesApi from 'resources/services/companies'

type DataType = Awaited<ReturnType<typeof CompaniesApi.getCanApprove>>

type OptionsType = Omit<
  UseQueryOptions<DataType, unknown, DataType, 'canApproveCompanies'>,
  'queryKey' | 'queryFn'
>

const useCanApproveCompaniesRequest = (options: OptionsType) => {
  return useQuery('canApproveCompanies', CompaniesApi.getCanApprove, options)
}

export default useCanApproveCompaniesRequest
