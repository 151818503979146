import styled from 'styled-components'
import { Page, PageContent } from 'components'

export const PageStyled = styled(Page)`
  text-align: center;
  overflow-x: hidden;
  max-width: 280px;
  margin: auto;
`

export const PageContentStyled = styled(PageContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
`

export const ImageStyled = styled.img`
  width: 170px;
  height: 170px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`
