import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

const usePageValidation = (condition: boolean, redirectTo: string) => {
  const history = useHistory()

  useLayoutEffect(() => {
    if (condition) {
      history.replace(redirectTo)
    }
  }, [history, condition, redirectTo])
}

export default usePageValidation
