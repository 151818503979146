import styled, { css } from 'styled-components'
import { Button as ButtonUI } from '@material-ui/core'
import { ButtonProps } from './interfaces'

const Button = styled(ButtonUI)<ButtonProps>`
  && {
    line-height: 24px;
    text-transform: none;
    font-size: 0.875rem;
  }

  font-weight: 600;

  &:disabled {
    opacity: 0.44;
  }

  ${({ color, theme }) =>
    color === 'inherit' &&
    css`
      color: ${theme.palette.colors.neutral[500]};
      font-weight: 500;
    `}
  ${({ color, theme, variant }) =>
    color === 'primary' &&
    variant === 'contained' &&
    css`
      &:disabled {
        color: ${theme.palette.primary.contrastText};
        background-color: ${theme.palette.primary.main};
      }
    `}
  ${({ theme, danger }) =>
    danger &&
    css`
      background: ${theme.palette.error.main};
      color: ${theme.palette.primary.contrastText};
      :hover {
        background: ${theme.palette.error.main};
      }
      :disabled {
        background: ${theme.palette.error.main};
      }
    `}
  ${({ variant }) =>
    variant === 'text' &&
    css`
      background: transparent !important;
    `}
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
`

Button.defaultProps = {
  size: 'large',
  disableElevation: true
}

export default Button
