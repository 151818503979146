import { Step, StepLabel } from '@material-ui/core'
import React, { memo } from 'react'
import { ReactComponent as StatusWarningIcon } from 'assets/svg/warning-status.svg'
import { VerticalLinearStepperProps } from './interface'
import { StepperStyled } from './styles'
import { Trans } from 'react-i18next'

const VerticalLinearStepper = ({
  steps,
  activeStep,
  error,
  totalErrors = 0
}: VerticalLinearStepperProps) => {
  const isCompleted = (index: number): boolean => {
    return activeStep > index
  }

  return (
    <StepperStyled activeStep={activeStep} orientation="vertical">
      {steps?.map((step, index) => {
        if (error && index === activeStep && step && step.errorLabel) {
          return (
            <Step key={index} completed={false}>
              <StepLabel error StepIconComponent={() => <StatusWarningIcon />}>
                <Trans>{step.errorLabel(totalErrors)}</Trans>
              </StepLabel>
            </Step>
          )
        }

        return (
          <Step key={index}>
            <StepLabel>
              {isCompleted(index) ? step.successLabel : step.defaultLabel}
            </StepLabel>
          </Step>
        )
      })}
    </StepperStyled>
  )
}

export default memo(VerticalLinearStepper)
