import React, { lazy } from 'react'
import { RouteProps } from 'routes/interfaces'

const CompanyPortalLayout = lazy(() => import('layouts/CompanyPortal'))
const Payment = lazy(() => import('containers/CompanyPortal/containers/Payment'))
const CompaniesList = lazy(
  () => import('containers/CompanyPortal/containers/CompaniesList')
)
const CompanyDetail = lazy(
  () => import('containers/CompanyPortal/containers/CompanyDetail')
)
const Dashboard = lazy(() => import('containers/CompanyPortal/containers/Dashboard'))
const LoansList = lazy(() => import('containers/CompanyPortal/containers/LoansList'))
const LoanDetail = lazy(() => import('containers/CompanyPortal/containers/LoanDetail'))
const NewBase = lazy(() => import('containers/CompanyPortal/containers/NewEmployeeBase'))

const companyPortalPath = '/portal-empresa'

const companyPortalRoutes: RouteProps[] = [
  {
    path: companyPortalPath,
    layout: CompanyPortalLayout,
    component: Dashboard,
    exact: true,
    feature: 'enduser_menu_hr_home'
  },
  {
    path: `${companyPortalPath}/propostas`,
    layout: CompanyPortalLayout,
    component: LoansList,
    exact: true,
    feature: 'enduser_menu_hr_loan'
  },
  {
    path: `${companyPortalPath}/propostas/:id`,
    layout: CompanyPortalLayout,
    component: LoanDetail,
    exact: true,
    feature: 'enduser_menu_hr_loan'
  },
  {
    path: `${companyPortalPath}/pagamentos`,
    layout: (props) => <CompanyPortalLayout {...props} fullWidth />,
    component: Payment,
    exact: true,
    feature: 'enduser_menu_hr_payment'
  },
  {
    path: `${companyPortalPath}/empresas/importacao`,
    layout: CompanyPortalLayout,
    component: NewBase,
    exact: true,
    feature: 'enduser_import_employee'
  },
  {
    path: `${companyPortalPath}/empresas/importacao/:id`,
    layout: CompanyPortalLayout,
    component: NewBase,
    exact: true,
    feature: 'enduser_import_employee'
  },
  {
    path: `${companyPortalPath}/empresas`,
    layout: CompanyPortalLayout,
    component: CompaniesList,
    exact: true,
    feature: 'enduser_menu_hr_company'
  },
  {
    path: `${companyPortalPath}/empresas/:id`,
    layout: CompanyPortalLayout,
    component: CompanyDetail,
    exact: true,
    feature: 'enduser_menu_hr_company'
  }
]

export default companyPortalRoutes
