import React, { FC, memo } from 'react'
import Typography from '../Typography'
import { ArrowBackProps } from './interfaces'
import { ContentStyled, ContainedIconStyled } from './style'
import { theme } from 'providers/Theme'

const ArrowBack: FC<ArrowBackProps> = ({ children, className, onClick }) => {
  return (
    <ContentStyled onClick={onClick} className={className} data-testid="arrow-back">
      <ContainedIconStyled />
      <Typography
        variant="body2"
        className="ml-1"
        color={theme.palette.colors.neutral[500]}
      >
        {children}
      </Typography>
    </ContentStyled>
  )
}

export default memo(ArrowBack)
