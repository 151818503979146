import React from 'react'
import { toast, ToastOptions } from 'react-toastify'
import { Toast } from 'components'
import { ToastMessages } from './interfaces'

const defaultOptions = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 8000,
  icon: false
}

const getOptions = (options?: ToastOptions) => ({ ...defaultOptions, ...options })

const success = ({ message, title }: ToastMessages, options?: ToastOptions) =>
  toast.success(
    () => <Toast message={message} type="success" titleName={title} />,
    getOptions(options)
  )

const error = ({ message, title }: ToastMessages, options?: ToastOptions) => {
  const opts = getOptions(options)
  const id = opts.toastId || ''

  if (opts.toastId === undefined || !toast.isActive(id)) {
    toast.error(<Toast message={message} type="error" titleName={title} />, opts)
  }
}

const warn = ({ message, title }: ToastMessages, options?: ToastOptions) =>
  toast.warn(
    <Toast message={message} type="warn" titleName={title} />,
    getOptions(options)
  )

const info = ({ message, title }: ToastMessages, options?: ToastOptions) =>
  toast.info(
    <Toast message={message} type="info" titleName={title} />,
    getOptions(options)
  )

const defaultToast = (message: string, options?: ToastOptions) =>
  toast(message, getOptions(options))

export default {
  success,
  error,
  warn,
  info,
  default: defaultToast
}
