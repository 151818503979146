import { createGlobalStyle } from 'styled-components'
import { getSpacing } from './mixing'
import './fonts.css'

const globalStyle = createGlobalStyle`
  body {
    line-height: normal;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.palette.colors.neutral[0]};
    overflow-x: hidden;
  }

  html,
  body,
  #root {
    width: 100%;
    min-height: 100%;
    height: auto;
    display: flex;
    font-family: 'Montserrat Medium';
  }

  strong,
  b {
    font-family: 'Montserrat Bold';
  }

  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }

  .flex-1 {
    flex: 1;
  }

  .overflow-y {
    overflow-y: auto;
  }

  .fullHeight {
    height: 100%;
  }

  .fullWidth{
    width: 100%;
  }

  .fitContent{
    width: fit-content;
  }

  .relative {
    position: relative;
  }

  .none {
    display: none;
  }

  ${getSpacing('margin', 'm')}
  ${getSpacing('padding', 'p')}
`

export default globalStyle
