import React, { FC } from 'react'
import i18n from 'commons/i18n'
import { Button, Typography } from 'components'
import { theme } from 'providers/Theme'
import { PageStyled, PageContentStyled, ImageStyled } from './styles'
import { ErrorPageProps } from './interfaces'

const ErrorPage: FC<ErrorPageProps> = ({ errorObject }) => {
  return (
    <PageStyled>
      <PageContentStyled>
        <ImageStyled src={errorObject.image} />
        {!!errorObject.title && (
          <Typography
            variant="body1"
            font="SemiBold"
            className="mb-3"
            color={theme.palette.colors.neutral[400]}
          >
            {errorObject.title}
          </Typography>
        )}
        <Typography
          variant="body3"
          className="mb-3"
          color={theme.palette.colors.neutral[400]}
        >
          {errorObject.message}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => window.location.replace('/')}
          fullWidth
        >
          {i18n.t('errorBoundary.back')}
        </Button>
      </PageContentStyled>
    </PageStyled>
  )
}

export default ErrorPage
