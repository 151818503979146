import styled, { css } from 'styled-components'
import { EmptyStyledProps, IconWrapperStyledProps } from './interfaces'

export const EmptyStyled = styled.div<EmptyStyledProps>`
  width: 100%;
  height: ${({ containerHeight, theme }) => theme.spacing(containerHeight)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  ${({ variant, theme }) =>
    variant === 'card' &&
    css`
      background-color: ${theme.palette.colors.neutral[50]};
      border-radius: ${theme.spacing(1)}px;
    `}
`

export const ContainerStyled = styled.div`
  max-width: ${({ theme }) => theme.spacing(27)}px;
`

export const IconWrapperStyled = styled.div<IconWrapperStyledProps>`
  position: relative;
  z-index: 1;

  ${({ iconShadow }) =>
    iconShadow &&
    css`
      ::before {
        content: '';
        position: absolute;
        width: 110px;
        height: 11px;
        background-color: ${({ theme }) => theme.palette.colors.neutral[200]};
        bottom: 4px;
        left: calc(50% - 55px);
        border-radius: 100%;
        z-index: 1;
      }
    `}

  svg {
    width: ${({ svgWidth = 72 }) => svgWidth}px;
    height: ${({ svgHeight = 72 }) => svgHeight}px;
    position: relative;
    z-index: 2;

    path {
      fill: ${({ theme }) => theme.palette.colors.neutral[300]};
    }
  }
`
