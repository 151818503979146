import React, { FC } from 'react'
import i18n from 'commons/i18n'
import { AutocompleteProps } from './interfaces'
import { AutocompleteStyled, PaperStyled, PopperStyled } from './styles'

const components = {
  default: {
    PaperComponent: PaperStyled,
    PopperComponent: PopperStyled
  },
  noPopper: {
    PaperComponent: PaperStyled
  }
}

const Autocomplete: FC<AutocompleteProps> = ({
  neutral,
  noOptionsText,
  PaperComponent,
  PopperComponent,
  autocompleteStyle,
  ...props
}) => {
  const customComponents = components[autocompleteStyle || ''] || {
    PaperComponent,
    PopperComponent
  }

  return (
    <AutocompleteStyled
      $neutral={neutral}
      noOptionsText={noOptionsText || i18n.t('form.noOptions')}
      {...customComponents}
      {...props}
    />
  )
}

export default Autocomplete
