import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'

const FormControlStyled = styled(FormControl)`
  .MuiInputLabel-root {
    letter-spacing: 0.2px;
    font-weight: 400;
  }
`

export { FormControlStyled }
