import axios from 'axios'
import qs from 'qs'
import defaultsDeep from 'lodash.defaultsdeep'
import { tenant } from 'white-label'
import { getLanguage } from 'commons/utils/locale'
import { getAuthToken } from 'commons/utils/auth'

const getAuthorization = () => {
  const { token } = getAuthToken()

  return {
    'x-tenant-id': tenant,
    Authorization: `Bearer ${token}`,
    access_token: token
  }
}

export const getConfig = async (
  headers?: Record<string, unknown>,
  removeAuthentication?: boolean
) => ({
  headers: {
    Accept: 'application/json',
    'Accept-Language': getLanguage(),
    ...(!removeAuthentication && getAuthorization()),
    ...(!!headers && headers)
  },
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'indices' })
  }
})

const restApi = (baseURL: string) => {
  const axiosApi = axios.create({
    baseURL
  })

  return {
    get: async <T>(url: string, params?: any) => {
      const options = await getConfig()

      return axiosApi
        .get<T>(url, { ...options, params })
        .then((resp) => {
          return resp.data
        })
        .catch((error) => {
          throw error
        })
    },
    post: async <T>(url: string, data: any, headers?: { [key: string]: string }) => {
      const options = await getConfig(headers)

      return axiosApi
        .post<T>(url, data, options)
        .then((resp) => {
          return resp.data
        })
        .catch((error) => {
          throw error
        })
    },
    put: async <T>(url: string, data: any, options?: any) => {
      const mergedOptions = defaultsDeep(
        options,
        await getConfig(undefined, options?.removeAuthentication)
      )

      return axiosApi
        .put<T>(url, data, mergedOptions)
        .then((resp) => {
          return resp.data
        })
        .catch((error) => {
          throw error
        })
    },
    patch: async <T>(url: string, data: any) => {
      const options = await getConfig()

      return axiosApi
        .patch<T>(url, data, options)
        .then((resp) => {
          return resp.data
        })
        .catch((error) => {
          throw error
        })
    },
    delete: async <T>(url: string, options: any) => {
      const mergedOptions = defaultsDeep(options, await getConfig())

      return axiosApi
        .delete<T>(url, mergedOptions)
        .then((resp) => {
          return resp.data
        })
        .catch((error) => {
          throw error
        })
    }
  }
}

export default restApi
