import React, { FC } from 'react'
import { PageStyled, PageScrollStyled } from './style'
import { PageProps } from './interfaces'

const Page: FC<PageProps> = ({ children, navbar, scroll = false, ...props }) => (
  <PageStyled {...props} hasNavbar={!!navbar}>
    {navbar}
    {!scroll ? children : <PageScrollStyled>{children}</PageScrollStyled>}
  </PageStyled>
)

export default Page
