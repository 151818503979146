import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'

const formatQueryStringParam = (search: string, param: string) =>
  search + (search.length > 0 ? '&' : '?') + param

const useQuerystring = () => {
  const location = useLocation()
  const history = useHistory()

  const set = (param: string, value: string) => {
    if (!param || !value || typeof param !== 'string' || typeof value !== 'string') {
      return
    }

    let search = ''
    let isNewParam = true

    if (!!param && typeof param === 'string' && location.search.indexOf('?') === 0) {
      location.search
        .replace(location.search.substring(0, 1), '')
        .split('&')
        .forEach((element: string) => {
          if (!element) return

          if (element.indexOf(param) === 0) {
            isNewParam = false
            search = formatQueryStringParam(search, param + '=' + value)

            return
          }
          search = formatQueryStringParam(search, element)
        })
    }

    if (isNewParam) {
      search = formatQueryStringParam(search, param + '=' + value)
    }

    history.push({ search })
  }

  const remove = (param?: string) => {
    let search = ''

    if (!!param && typeof param === 'string' && location.search.indexOf('?') === 0) {
      location.search
        .replace(location.search.substring(0, 1), '')
        .split('&')
        .forEach((element: string) => {
          if (element.indexOf(param) !== 0) {
            search = formatQueryStringParam(search, element)
          }
        })
    } else if (param !== undefined) {
      search = location.search
    }

    history.push({ search })
  }

  const get = (param: string) => {
    if (param && typeof param === 'string') {
      const locationSearch = qs.parse(location.search, {
        ignoreQueryPrefix: true
      })[param]

      return locationSearch && locationSearch?.toString()
    }

    return undefined
  }

  return {
    set,
    remove,
    get
  }
}

export default useQuerystring
