import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Event from 'third-party/event'
import { useStoreActions, useStoreState } from 'commons/hooks/useStore'
import { Feature, PrivateRoute } from 'components'
import { AppRouteProps, RouteAccessConfiguration } from './interfaces'
import payrollRoutes from 'routes/payroll/simulation'
import advanceRoutes from 'routes/advance'

const PAYROLL_SAFELIST = payrollRoutes.map((route) => route.path)
const ADVANCE_SAFELIST = advanceRoutes.map((route) => route.path)

const AppRoute: FC<AppRouteProps> = ({
  component: Component,
  layout: Layout,
  title,
  path,
  condition,
  feature,
  exact,
  location
}) => {
  const history = useHistory()
  const { simulationLoan, isAuthenticated, isNewAdvance } = useStoreState(
    ({ advance: advanceState, loan: loanStates, user: userState }) => ({
      simulationLoan: loanStates,
      isAuthenticated: userState.auth.isAuthenticated,
      isNewAdvance: advanceState.isNewAdvance
    })
  )
  const { setSimulationLoan, setIsNewAdvance, setPixData } = useStoreActions(
    ({ loan: loanActions, advance: advanceActions }) => ({
      setSimulationLoan: loanActions.setSimulationLoan,
      setIsNewAdvance: advanceActions.setIsNewAdvance,
      setPixData: advanceActions.setPixData
    })
  )

  const ComponentWithLayout = (props) => {
    return Layout ? (
      <Layout {...props} title={title}>
        <Component {...props} title={title} />
      </Layout>
    ) : (
      <Component {...props} title={title} />
    )
  }

  const component =
    isAuthenticated || condition === 'OPEN' ? ComponentWithLayout : Component

  const routeAccessConfiguration: RouteAccessConfiguration = {
    IS_NOT_AUTHENTICATED: {
      accessCondition: !isAuthenticated
    },
    DEFAULT: { accessCondition: isAuthenticated },
    OPEN: { accessCondition: true }
  }

  const {
    accessCondition = routeAccessConfiguration.DEFAULT.accessCondition
  }: {
    accessCondition?: boolean
  } = condition ? routeAccessConfiguration[condition] : {}

  useEffect(() => {
    if (typeof path == 'string' && simulationLoan && !PAYROLL_SAFELIST.includes(path)) {
      setSimulationLoan(null)
    }
  }, [simulationLoan, setSimulationLoan, path])

  useEffect(() => {
    if (typeof path == 'string' && !ADVANCE_SAFELIST.includes(path) && isNewAdvance) {
      setIsNewAdvance(false)
      Event.track('ADVANCE_CONVERSION_PROOF-DISPLAYED')
      setPixData(null)
    }
  }, [setIsNewAdvance, isNewAdvance, setPixData, path])

  if (accessCondition) {
    const privateRoute = (
      <PrivateRoute component={component} path={path} exact={exact} location={location} />
    )

    if (feature) {
      return (
        <Feature feature={feature} onDeactivated={() => history.replace('/404')}>
          {privateRoute}
        </Feature>
      )
    }

    return privateRoute
  }

  return null
}

export default AppRoute
