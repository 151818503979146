import React, { FC, memo } from 'react'
import { getFieldProps, areEqual } from '../Field/utils'
import FormHelperText from '../FormHelperText'
import AlertHelperText from '../AlertHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import { FormControlStyled } from './styles'
import { FormControlProps } from './interfaces'

const renderAlertText = (maxCharacters, value, helperText) => {
  const showAlert = maxCharacters && !helperText

  return showAlert && <AlertHelperText value={value} max={maxCharacters} />
}

const FormControl: FC<FormControlProps> = ({ customComponent: Input, ...props }) => {
  const {
    id,
    label,
    helperText,
    fullWidth,
    required = false,
    error,
    margin = 'normal',
    maxCharacters = null,
    disabled,
    name,
    ...fieldProps
  } = getFieldProps(props)
  const getIsShrink = () => fieldProps.type === 'date' || fieldProps.value !== ''
  const fieldIdentifier = id || name
  const labelledby = `${fieldIdentifier}-label`
  const describedby = `${fieldIdentifier}-helperText`

  return (
    <FormControlStyled
      error={error}
      fullWidth={fullWidth}
      required={required}
      margin={margin}
      disabled={disabled}
    >
      {label && (
        <InputLabel
          id={labelledby}
          shrink={disabled || getIsShrink()}
          htmlFor={fieldIdentifier}
        >
          {label}
        </InputLabel>
      )}
      <Input
        {...fieldProps}
        name={name}
        disabled={disabled}
        id={fieldIdentifier}
        aria-labelledby={labelledby}
        aria-describedby={describedby}
        inputProps={{
          'aria-labelledby': labelledby,
          'aria-describedby': describedby,
          ...fieldProps?.inputProps
        }}
      />
      {helperText && (
        <FormHelperText id={describedby} aria-labelledby={labelledby} role="alert">
          {helperText}
        </FormHelperText>
      )}
      {renderAlertText(maxCharacters, fieldProps.value, helperText)}
    </FormControlStyled>
  )
}

export default memo(FormControl, areEqual)
