import styled, { css } from 'styled-components'
import { TagProps } from './interfaces'

export const TagStyled = styled.div<TagProps>`
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  width: fit-content;
  padding: ${({ theme }) => theme.spacing(0.5, 1)};
  text-align: center;

  ${({ variant, color, tagColor, borderRadius }) => css`
    background-color: ${variant === 'contained' ? tagColor : 'transparent'};
    border-color: ${variant === 'contained' ? tagColor : color};
    color: ${color};
    border-radius: ${borderRadius};
  `}
`
