import React from 'react'
import i18n from 'commons/i18n'
import SuccessImage from 'assets/png/success.png'
import { ImageStyled, TextStyled } from './styles'

const Success = () => {
  return (
    <>
      <ImageStyled src={SuccessImage} alt={i18n.t('general.success')} />
      <TextStyled>{i18n.t('employee.userImport.successBaseUpdate')}</TextStyled>
    </>
  )
}

export default Success
