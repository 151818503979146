import styled from 'styled-components'
import { DialogActions } from '@material-ui/core'

const DialogActionsStyled = styled(DialogActions)`
  background: ${({ theme }) => theme.palette.colors.neutral[50]};
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(4)}px;
  border-radius: 0px 0px 16px 16px;
`

export default DialogActionsStyled
