import {
  Date as DateUtils,
  CNPJ as CNPJUtils,
  CPF as CPFUtils,
  Money as MoneyUtils,
  String as StringUtils,
  Phone as PhoneUtils
} from '@base39/ui-utils'
import { TypeValue, TypeMetadata } from './interfaces'
import i18n from 'commons/i18n/index'

export const i18nOverrides = {
  'pt-BR': {
    overrides: {
      dropzone: {
        accepted: '',
        button: i18n.t('employee.userImport.dropzoneButton')
      },
      fileTypes: i18n.t('employee.userImport.fileTypes')
    }
  }
}

const validateCpf = (value: TypeValue) => {
  const strippedCpf = CPFUtils.strip(value)

  return {
    value: strippedCpf.padStart(11, '0')
  }
}

const validateCnpj = (value: TypeValue, metadata?: TypeMetadata) => {
  const strippedCnpj = CNPJUtils.strip(value).padStart(14, '0')
  const storedCompanies =
    metadata?.companies?.filter((company) => company.cnpj === strippedCnpj) || []

  if (!CNPJUtils.isValid(strippedCnpj) || !storedCompanies.length) {
    return {
      value: strippedCnpj,
      info: [{ message: i18n.t('form.cnpjInexistent'), level: 'error' }]
    }
  }

  return {
    value: strippedCnpj
  }
}

const validateDate = (value: TypeValue) => {
  const formattedDate = (value || '').toString().replace(/\s/g, '')

  if (!DateUtils.isValidDate(formattedDate) && !StringUtils.isEmpty(formattedDate)) {
    return {
      value: formattedDate,
      info: [{ message: i18n.t('form.dateInvalid'), level: 'error' }]
    }
  }

  return {
    value: formattedDate
  }
}

const validateCurrencyNumber = (value: TypeValue) => {
  const formattedNumber = MoneyUtils.sanitizeMoney(value)

  if (/-/.test((value || '').toString())) {
    return {
      value: `-${formattedNumber}`,
      info: [{ message: i18n.t('form.numberInvalid'), level: 'error' }]
    }
  }

  return {
    value: formattedNumber
  }
}

const validatePhone = (value: TypeValue) => {
  const strippedPhone = PhoneUtils.strip(value)

  if (/^.{14,}$/.test(strippedPhone)) {
    return {
      value: PhoneUtils.strip(value),
      info: [{ message: i18n.t('form.numberInvalid'), level: 'error' }]
    }
  }

  return {
    value: PhoneUtils.strip(value)
  }
}

const validations = {
  cpf: validateCpf,
  cnpj: validateCnpj,
  date: validateDate,
  currencyNumber: validateCurrencyNumber,
  phone: validatePhone
}

export const validateFlatfileField = (
  field: keyof typeof validations,
  value?: TypeValue,
  metadata?: TypeMetadata
) => {
  if (!value) {
    return {
      value: undefined
    }
  }

  return validations[field](value, metadata)
}
