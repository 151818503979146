import React, { FC } from 'react'
import i18n from 'commons/i18n'
import { Dialog, DialogHeader } from 'components/Dialog'
import documentIconPath from 'assets/svg/document_large.svg'
import { Typography, Button } from 'components'
import { InstructionsModalProps } from './interfaces'
import {
  IconContainer,
  DialogTitleStyled,
  DialogContentStyled,
  DialogActionsStyled,
  DownloadIconStyled
} from './styles'

const InstructionsModal: FC<InstructionsModalProps> = ({
  isOpen,
  onClose,
  disabled,
  onImportClick
}) => {
  const downloadTemplate = () => {
    const a = document.createElement('a')
    a.href = '/modelo-nova-base.csv'
    a.click()
  }

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      blockBackDropClick
      onClose={onClose}
      scroll="body"
    >
      <DialogHeader onClose={onClose} />
      <DialogTitleStyled>{i18n.t('employee.userImport.title')}</DialogTitleStyled>
      <IconContainer>
        <img
          src={documentIconPath}
          alt={i18n.t('employee.userImport.importInstructions.documentIconAlt')}
        />
      </IconContainer>
      <DialogContentStyled>
        <Typography variant="body2" font="SemiBold">
          {i18n.t('employee.userImport.importInstructions.fileModel')}
        </Typography>
        <ul>
          <li className="mb-1">
            <Typography variant="body3">
              {i18n.t('employee.userImport.importInstructions.filesInFormat')}{' '}
              <strong>
                {i18n.t('employee.userImport.importInstructions.fileExtension')}
              </strong>
            </Typography>
          </li>
          <li className="mb-1">
            <Typography variant="body3">
              {i18n.t('employee.userImport.importInstructions.fileLine')}
            </Typography>
          </li>
        </ul>
        <Typography variant="body2" font="SemiBold">
          {i18n.t('employee.userImport.importInstructions.requiredFields')}
        </Typography>
        <ul>
          <li className="mb-1">
            <Typography variant="body3" font="SemiBold">
              {i18n.t('employee.userImport.importInstructions.fileCNPJ')}
            </Typography>
          </li>
          <li className="mb-1">
            <Typography variant="body3" font="SemiBold">
              {i18n.t('employee.userImport.importInstructions.fileCPF')}
            </Typography>
          </li>
        </ul>
        <Typography variant="body2" font="SemiBold">
          {i18n.t('employee.userImport.importInstructions.optionalFields')}
        </Typography>
        <ul>
          <li className="mb-1">
            <Typography variant="body3">
              {i18n.t('employee.userImport.importInstructions.name')}
            </Typography>
          </li>
          <li className="mb-1">
            <Typography variant="body3">
              {i18n.t('employee.userImport.importInstructions.salary')}
            </Typography>
          </li>
          <li className="mb-1">
            <Typography variant="body3">
              {i18n.t('employee.userImport.importInstructions.netSalary')}
            </Typography>
          </li>
          <li className="mb-1">
            <Typography variant="body3">
              {i18n.t('employee.userImport.importInstructions.consignable')}
            </Typography>
          </li>
        </ul>
      </DialogContentStyled>
      <DialogActionsStyled>
        <Button
          color="primary"
          variant="outlined"
          disabled={disabled}
          width="270"
          onClick={downloadTemplate}
        >
          <DownloadIconStyled className="mr-2" />
          {i18n.t('employee.userImport.importInstructions.downloadSheetModel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onImportClick}
          disabled={disabled}
          width="270"
          className="mt-2 ml-0"
        >
          {i18n.t('employee.userImport.title')}
        </Button>
      </DialogActionsStyled>
    </Dialog>
  )
}

export default InstructionsModal
