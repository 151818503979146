import styled, { css } from 'styled-components'
import { ContrastDataProps } from './interfaces'

export const ContrastDataStyled = styled.div<ContrastDataProps>`
  ${({ borderRadius, theme }) =>
    borderRadius &&
    css`
      border-radius: ${theme.spacing(borderRadius)}px;
    `}

  ${({ padding, theme }) =>
    padding &&
    css`
      padding: ${theme.spacing(padding)}px;
    `}

  ${({ backgroundColor, theme }) =>
    css`
      background-color: ${backgroundColor || theme.palette.colors.neutral[100]};
    `}

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;
    `}
`
