import { useQuery } from 'react-query'
import toast from 'commons/utils/toast'
import i18n from 'commons/i18n'
import CompaniesApi from 'resources/services/companies'

export const useGetCompanyByIdRequest = (id: string) => {
  return useQuery(['GetCompanyId', id], () => CompaniesApi.getById(id), {
    enabled: !!id,
    onError: () => toast.error({ message: i18n.t('general.toast.emptyError') })
  })
}
