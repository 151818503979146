import React from 'react'
import { Grid, Skeleton } from 'components'

function DataSkeleton() {
  const getSpecificData = () => {
    return [1, 2, 3].map((it) => (
      <Grid key={it} container className="mb-2 mt-2">
        <Grid item xs={6}>
          <Skeleton width={250} height={16} className="mb-1" />
          <Skeleton height={24} className="mr-4" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton width={250} height={16} className="mb-1" />
          <Skeleton height={24} className="mr-4" />
        </Grid>
      </Grid>
    ))
  }

  const skeletonData = () => (
    <>
      <Grid item xs={12}>
        <Skeleton width={200} height={24} />
        {getSpecificData()}
      </Grid>
      <Grid item xs={12} className="mt-2">
        <Skeleton width={200} height={24} />
        {getSpecificData()}
      </Grid>
    </>
  )

  return skeletonData()
}

export default DataSkeleton
