import React from 'react'
import { Pie, ComputedDatum } from '@nivo/pie'
import { BasicTooltip } from '@nivo/tooltip'
import { PieChartComponent } from './interfaces'
import { theme } from 'providers/Theme'

const PieTooltip = <R,>({ datum }: { datum: ComputedDatum<R> }) => (
  <BasicTooltip
    id={datum.label}
    value={datum.formattedValue}
    enableChip={true}
    color={datum.color}
  />
)

const PieChart: PieChartComponent = ({
  data,
  width,
  height,
  chartPosition,
  useColorsFromData,
  legendAnchor = 'right',
  legendDirection = 'column',
  legendTranslateX = 140,
  legendTranslateY = 0,
  enableOutsideLabel,
  outsideLabelText,
  outsideLabelTextColor,
  enableInnerLabel,
  innerLabelTextColor,
  enableLegend,
  legendSpacing = 2,
  legendSymbolShape = 'circle',
  legendSymbolSize = 14,
  legendTextColor,
  fontSize = 12
}) => {
  return (
    <Pie
      {...(useColorsFromData ? { colors: { datum: 'data.color' } } : {})}
      theme={{
        fontSize: fontSize,
        fontFamily: 'Montserrat Medium'
      }}
      height={height}
      width={width}
      data={data}
      margin={{
        top: chartPosition?.top ? chartPosition.top : 40,
        right: chartPosition?.right ? chartPosition.right : 0,
        bottom: chartPosition?.bottom ? chartPosition.bottom : 40,
        left: chartPosition?.left ? chartPosition.left : 0
      }}
      innerRadius={0.6}
      padAngle={1.5}
      cornerRadius={0}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      enableArcLinkLabels={!!enableOutsideLabel}
      arcLinkLabel={outsideLabelText || 'value'}
      arcLinkLabelsSkipAngle={1}
      arcLinkLabelsTextColor={
        outsideLabelTextColor || { from: 'color', modifiers: [['darker', 2]] }
      }
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      enableArcLabels={!!enableInnerLabel}
      arcLabel={'value'}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={
        innerLabelTextColor || { from: 'color', modifiers: [['brighter', 5]] }
      }
      tooltip={PieTooltip}
      legends={
        enableLegend
          ? [
              {
                anchor: legendAnchor,
                direction: legendDirection,
                justify: false,
                translateX: legendTranslateX,
                translateY: legendTranslateY,
                itemsSpacing: legendSpacing,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: legendTextColor || theme.palette.colors.neutral[500],
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: legendSymbolSize,
                symbolShape: legendSymbolShape,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: theme.palette.colors.neutral[600]
                    }
                  }
                ]
              }
            ]
          : undefined
      }
    />
  )
}

export default PieChart
