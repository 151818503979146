import React, { memo } from 'react'
import { ThemeProvider as ThemeProviderStyled } from 'styled-components'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ReactComponent as Close } from 'assets/svg/close.svg'
import { ToastContainerStyled } from './styles'
import { theme } from './utils'

const ThemeProvider = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProviderStyled theme={theme}>
        {children}
        <ToastContainerStyled className="toast-position" closeButton={() => <Close />} />
      </ThemeProviderStyled>
    </MuiThemeProvider>
  )
}

export default memo(ThemeProvider)
export { theme }
