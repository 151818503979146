import styled from 'styled-components'
import CircularProgress from '../CircularProgress'
import TextField from '@material-ui/core/TextField'

export const ProgressIconStyled = styled(CircularProgress).attrs({
  size: 17
})`
  position: absolute;
  cursor: pointer;
  left: calc(100% - 34px);
  top: calc(50% - 9px);
`

export const TextFieldStyled = styled(TextField)`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.colors.neutral[50]};

  .MuiInputBase-root {
    height: ${({ theme }) => theme.spacing(5)}px;
    font-size: 0.875rem;
    padding: ${({ theme }) => theme.spacing(0, 1)};
  }

  :not(.MuiInputBase-root.Mui-disabled) {
    :hover,
    :focus-within {
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
      outline: 1px solid ${({ theme }) => theme.palette.colors.neutral[100]};
    }
  }

  input::placeholder {
    opacity: 1;
  }

  ::after {
    content: none;
  }

  ::before {
    content: none;
  }

  fieldset {
    border: none;
  }
`

export const PopperStyled = styled.div`
  position: absolute;
  z-index: 9;
  overflow: none;
`

export const PaperStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  letter-spacing: 0;
  color: ${({ theme }) => theme.palette.colors.neutral[600]};
  background-color: ${({ theme }) => theme.palette.colors.neutral[50]};
  font-family: 'Montserrat SemiBold';
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  line-height: 1.5rem;

  .MuiAutocomplete-listbox {
    ::-webkit-scrollbar {
      width: ${({ theme }) => theme.spacing(1.25)}px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: ${({ theme }) => theme.spacing(0.75)}px;
      background-color: ${({ theme }) => theme.palette.colors.neutral[200]};
    }
  }
`
