import { RestApi } from '../../api/paketa'
import { User } from 'interfaces/user'
import { UpdateRequest } from './interfaces'

const path = '/v1/customers'

const Customers = {
  get() {
    return RestApi.get<User>(`${path}/me`)
  },
  update(data: UpdateRequest) {
    return RestApi.patch(`${path}/me`, data)
  }
}

export default Customers
