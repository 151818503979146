import React, { FC } from 'react'
import { Pagination as PaginationMUI, PaginationProps } from '@material-ui/lab'

const Pagination: FC<PaginationProps> = ({ color, onChange, page, count }, params) => {
  return (
    <PaginationMUI
      {...params}
      color={color}
      onChange={onChange}
      page={page}
      count={count}
    />
  )
}

export default Pagination
