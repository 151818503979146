import React, { FC } from 'react'
import i18n from 'commons/i18n'
import { Typography, Skeleton as SkeletonMui } from 'components'
import { CardItemComponents, CardItemProps } from './interfaces'
import {
  CardItemStyled,
  RightSideStyled,
  ActionsStyled,
  SectionStyled,
  ArrowRightIconStyled
} from './styles'

const CardItem: FC<CardItemProps> & CardItemComponents = ({
  children,
  onClick,
  ...props
}) => (
  <CardItemStyled
    aria-label={i18n.t('general.documentCard')}
    onClick={onClick}
    {...props}
  >
    {children}
    {!!onClick && <ArrowRightIconStyled aria-label={i18n.t('general.iconArrow')} />}
  </CardItemStyled>
)

const LeftSide: FC = ({ children }) => <div>{children}</div>

const RightSide: FC = ({ children }) => <RightSideStyled>{children}</RightSideStyled>

const Actions: FC = ({ children }) => <ActionsStyled>{children}</ActionsStyled>

const Skeleton: FC = () => (
  <>
    <SectionStyled>
      <Typography variant="body4" font="SemiBold">
        <SkeletonMui width={75} />
      </Typography>
      <Typography lineHeight={26} variant="body2" font="SemiBold">
        <SkeletonMui width={220} height={20} />
      </Typography>
      <Typography lineHeight={17} variant="body3" font="Medium">
        <SkeletonMui width={180} height={20} />
      </Typography>
    </SectionStyled>
  </>
)

CardItem.LeftSide = LeftSide
CardItem.RightSide = RightSide
CardItem.Actions = Actions
CardItem.Skeleton = Skeleton

export default CardItem
