import { Company } from 'interfaces/company'
import { BaseApi } from '../../api/paketa'
import { CompaniesCanApproveResponse, CompaniesListResponse } from './interfaces'

const path = '/companies'

const Companies = {
  getById(companyId: string) {
    return BaseApi.get<Company>(`${path}/${companyId}`)
  },
  list({ q = '', page = 1, pageSize = 10, sort = 'tradeName' }) {
    const query = { q }
    const pagination = { page, pageSize, sort }
    const params = { query, pagination }

    return BaseApi.get<CompaniesListResponse>(path, params)
  },
  getCanApprove() {
    return BaseApi.get<CompaniesCanApproveResponse>(`${path}/canApprove`)
  }
}

export default Companies
