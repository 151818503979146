import React from 'react'
import IconButton from '../../IconButton'
import { Close as CloseIcon } from '@material-ui/icons'
import { DialogHeaderStyled, ActionsContainerStyled } from './style'
import { DialogHeaderProps } from './interfaces'

const DialogHeader = ({ disabled, onClose, id }: DialogHeaderProps) => (
  <DialogHeaderStyled className="pb-0" disableTypography>
    <ActionsContainerStyled>
      {onClose && (
        <IconButton
          data-testid="icon-button"
          disabled={disabled}
          color="inherit"
          onClick={onClose}
          id={id}
        >
          <CloseIcon />
        </IconButton>
      )}
    </ActionsContainerStyled>
  </DialogHeaderStyled>
)

export default DialogHeader
