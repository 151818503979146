import Event from 'third-party/event'
import { useQuery } from 'react-query'
import { useStoreActions, useStoreState } from 'commons/hooks/useStore'
import CreditLinesApi from 'resources/services/credit-lines'
import { useLogout } from '../../../commons/hooks'

export const useCreditLinesRequest = () => {
  const logout = useLogout()

  const { auth, user } = useStoreState(({ user: userState }) => ({
    auth: userState.auth,
    user: userState.user
  }))

  const { isAuthenticated } = auth

  const { payrollSetData, advanceSetData } = useStoreActions(
    ({ advance: advanceActions, payroll: payrollActions }) => ({
      payrollSetData: payrollActions.setData,
      advanceSetData: advanceActions.setData
    })
  )

  return useQuery('getCreditLine', CreditLinesApi.get, {
    enabled: !!user && isAuthenticated,
    onSuccess: (data) => {
      const payrollCreditLine = data.find((obj) => obj.type === 'PAYROLL')
      if (payrollCreditLine) {
        payrollSetData(payrollCreditLine)
      }

      const advanceCreditLine = data.find((obj) => obj.type === 'ADVANCE')
      if (advanceCreditLine) {
        Event.track('ADVANCE_ACTIVATE_DISPLAYED')
        advanceSetData(advanceCreditLine)
      }
    },
    onError: (err) => {
      console.error('Error fetching credit lines: ', err)
      logout()
    }
  })
}
