import { QueryClient } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false
    },
    queries: {
      cacheTime: 0,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
})

export default queryClient
