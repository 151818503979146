import React, { memo } from 'react'
import { ButtonProps } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded'
import { Button, Feature } from 'components'
import i18n from 'commons/i18n'
import { ImportButtonProps } from './interfaces'
import { LinkStyled } from './styles'

const defaultButtonProps = {
  color: 'primary',
  variant: 'outlined',
  size: 'medium'
} as ButtonProps

const ImportButton = ({ isNewBase }: ImportButtonProps) => {
  const text = i18n.t(isNewBase ? 'companyPortal.newBase' : 'company.updatedBase')
  const icon = isNewBase ? <AddIcon /> : null

  return (
    <>
      <Feature feature="enduser_import_employee">
        <LinkStyled to="/portal-empresa/empresas/importacao">
          <Button startIcon={icon} {...defaultButtonProps}>
            {text}
          </Button>
        </LinkStyled>
      </Feature>
      <Feature feature="enduser_import_employee" inverse>
        <Button
          startIcon={icon}
          onClick={(ev) => {
            ev.stopPropagation()
            document.getElementById('open-instructions-modal-button')?.click()
          }}
          {...defaultButtonProps}
        >
          {text}
        </Button>
      </Feature>
    </>
  )
}

export default memo(ImportButton)
