import styled from 'styled-components'
import { Stepper } from '@material-ui/core'

export const StepperStyled = styled(Stepper)`
  .MuiStepLabel-label {
    color: ${({ theme }) => theme.palette.colors.neutral[200]};
    font-size: 12px;
  }
  .MuiStepIcon-completed {
    color: ${({ theme }) => theme.palette.colors.success[400]};
  }

  .MuiStepLabel-completed {
    color: ${({ theme }) => theme.palette.colors.success[400]};
    font-size: 12px;
  }

  .MuiSvgIcon-root {
    .Mui-disabled {
      color: ${({ theme }) => theme.palette.colors.neutral[200]};
    }

    .Mui-completed {
      color: ${({ theme }) => theme.palette.colors.success[400]};
    }
  }

  .MuiStepIcon-root {
    color: ${({ theme }) => theme.palette.colors.neutral[200]};
  }

  .MuiStepIcon-active {
    color: ${({ theme }) => theme.palette.colors.neutral[500]};
  }

  .MuiStepIcon-completed {
    color: ${({ theme }) => theme.palette.colors.success[400]};
  }

  .MuiStepLabel-active {
    color: ${({ theme }) => theme.palette.colors.neutral[500]};
    font-size: 12px;
  }

  .MuiStepConnector-line {
    border-color: ${({ theme }) => theme.palette.colors.neutral[200]};
  }

  .MuiStepConnector-vertical {
    padding: 0;
    margin-left: 6px;
  }

  .MuiStepIcon-text {
    fill: none;
  }

  .MuiSvgIcon-root {
    width: 12px;
    height: 12px;
  }

  .MuiStepLabel-root {
    height: 12px;
  }
`
