import React, { FC, memo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import { DragDrop, useUppy } from '@uppy/react'
import { getUploadParameters, defaultOptions, validateMimeTypeFromBase64 } from './utils'
import { WrapperStyled } from '../../styles'
import { UppyUIProps } from './interfaces'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'

const UppyUI: FC<UppyUIProps> = ({
  children,
  className,
  cpf,
  onFileUploadComplete,
  onFileUploadFailed,
  documentType,
  setLoading
}) => {
  const uuidv4String = uuidv4()

  const uppy = useUppy(() =>
    new Uppy({
      ...defaultOptions,
      restrictions: {
        allowedFileTypes: ['.png', '.jpeg', '.jpg'],
        maxFileSize: null,
        maxNumberOfFiles: 1
      },
      onBeforeFileAdded: (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.data)
        reader.onloadend = () => {
          if (!validateMimeTypeFromBase64(reader.result as string, file.type)) {
            uppy.emit('restriction-failed', file, {
              message: defaultOptions.locale.strings.mimeTypeDoesNotMatch
            })
            uppy.cancelAll()
            return false
          }
        }
        return true
      }
    })
      .on('file-added', () => {
        setLoading(true)
      })
      .on('complete', () => {
        setLoading(false)
        uppy.reset()
      })
      .on('cancel-all', () => {
        setLoading(false)
      })
      .on('upload-success', (file) => {
        const extendedFileName = `${uuidv4String}-${file.meta.name}`

        const fileItem = {
          filename: extendedFileName,
          key: `${cpf}/${extendedFileName}`,
          type: documentType
        }

        onFileUploadComplete(fileItem)
      })
      .on('upload-error', (_, error) => {
        onFileUploadFailed({ error })
        setLoading(false)
      })
      .on('restriction-failed', (_, { message }) => {
        onFileUploadFailed({ message })
      })
      .use(AwsS3, {
        getUploadParameters: (file) =>
          getUploadParameters(
            `${cpf}/${uuidv4String}-${file.meta.name}`,
            file?.type as string,
            cpf
          )
      })
  )

  return (
    <button className={className}>
      <WrapperStyled>
        <DragDrop id={`doc-${documentType}`} uppy={uppy} />
      </WrapperStyled>
      {children}
    </button>
  )
}

export default memo(UppyUI)
