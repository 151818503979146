import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export const DialogStyled = styled(Dialog)`
  .paper {
    border-radius: 16px;
    ${({ theme }) => `
      ${theme.breakpoints.down('sm')} {
        max-width: calc(100% - ${theme.spacing(4)}px);
      }
    `}
  }

  .paperScrollPaper {
    margin: ${({ theme, fullScreen }) => !fullScreen && `${theme.spacing(2)}px`};
    max-height: ${({ theme, fullScreen }) =>
      !fullScreen && `calc(100% - ${theme.spacing(4)}px)`};
  }

  ${({ scroll }) =>
    scroll === 'body' &&
    `
    .paper {
      margin: 0;
    }
  `}
`
