import styled, { css } from 'styled-components'
import { CellWrapperStyledProps, OverflowWrapperStyledProps } from './interfaces'

const bolderCellCss = css`
  span {
    border: none;
    color: ${({ theme }) => theme.palette.colors.neutral[600]};
    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 18px;
  }
`

export const LoadingWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 150px;
`

export const CellWrapperStyled = styled.div<CellWrapperStyledProps>`
  && div.cell {
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: flex-start;
    padding-left: 14px;
    border: none;

    span {
      border: none;
      color: ${({ theme }) => theme.palette.colors.neutral[700]};
      font-weight: 500;
      font-size: 0.75rem;
      letter-spacing: 0;
      line-height: 15px;
    }

    ${({ isBolder }) => isBolder && bolderCellCss}
  }
`

export const OverflowWrapperStyled = styled.div<OverflowWrapperStyledProps>`
  position: relative;
  width: -webkit-fill-available;
  height: 620px;

  @media (max-width: 1200px) {
    font-size: 440px;
  }

  ${({ maxWidth }) => `max-width: ${maxWidth};`}
  overflow-x: auto;
  overflow-y: hidden;
`
export const RelativeWrapperStyled = styled.div`
  position: relative;
  width: fit-content;
  height: 620px;

  @media (max-width: 1200px) {
    font-size: 440px;
  }
`

export const HeaderStyled = styled.div`
  display: flex;
`

const getPropMinWidth = ({ width }) => css`
  min-width: ${width}px;
  max-width: ${width}px;
`

export const HeaderItemStyled = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  ${getPropMinWidth};
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0;
  padding: 14px;
  line-height: 15px;
`

export const WrapperStyled = styled.div`
  && {
    height: 100%;
    width: 100%;
    padding-bottom: 45px;
    outline: none;
    * {
      outline: none;
    }
    div [aria-label='grid'].ReactVirtualized__Grid {
      overflow-x: hidden !important;
      overflow-y: auto !important;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`
