import { lazy } from 'react'
import i18n from 'commons/i18n'
import { RouteProps } from 'routes/interfaces'

const MinimalLayout = lazy(() => import('layouts/Minimal'))
const InsertValue = lazy(() => import('containers/Products/Advance/Engine/InsertValue'))
const PixData = lazy(() => import('containers/Products/Advance/Engine/PixData'))
const ConfirmData = lazy(() => import('containers/Products/Advance/Engine/ConfirmData'))
const Detail = lazy(() => import('containers/Products/Advance/Detail'))
const Historic = lazy(() => import('containers/Products/Advance/Historic'))

const feature = 'enduser_advance_salary'

const Advance: RouteProps[] = [
  {
    path: '/antecipacao',
    layout: MinimalLayout,
    component: InsertValue,
    title: i18n.t('advance.general.title'),
    feature,
    exact: true
  },
  {
    path: '/antecipacao/pix',
    layout: MinimalLayout,
    component: PixData,
    feature,
    title: i18n.t('advance.general.title'),
    exact: true
  },
  {
    path: '/antecipacao/confirmacao',
    layout: MinimalLayout,
    component: ConfirmData,
    feature,
    title: i18n.t('advance.general.title'),
    exact: true
  },
  {
    path: '/antecipacao/historico',
    layout: MinimalLayout,
    component: Historic,
    feature,
    title: i18n.t('advance.historic.title'),
    exact: true
  },
  {
    path: '/antecipacao/:id',
    layout: MinimalLayout,
    component: Detail,
    feature,
    title: i18n.t('advance.historic.title'),
    exact: true
  }
]

export default Advance
