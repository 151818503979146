import styled, { css } from 'styled-components'
import { default as MuiTextField } from '@material-ui/core/TextField'
import { TextFieldStyledProps } from './interfaces'

export const TextFieldStyled = styled(MuiTextField)<TextFieldStyledProps>`
  ${({ $withoutBorder }) =>
    $withoutBorder &&
    css`
      .MuiOutlinedInput-root {
        fieldset {
          border: none;
        }
      }
    `}

  .MuiFormLabel-root {
    font-size: 1rem;
    font-family: 'Montserrat SemiBold';
    color: ${({ theme }) => theme.palette.colors.neutral[500]};
    z-index: 1;

    &.MuiInputLabel-shrink {
      font-family: 'Montserrat Regular';
    }
  }

  .MuiInputBase-root {
    margin: 0;

    &.Mui-disabled {
      background: ${({ theme }) => theme.palette.colors.neutral[50]};
    }
  }

  .MuiInput-input {
    font-size: 1rem;
    font-family: 'Montserrat SemiBold';
    color: ${({ theme }) => theme.palette.colors.neutral[500]};

    &.Mui-disabled {
      background: transparent;
    }
  }

  .MuiInput-underline:hover,
  .MuiInput-underline:focus-within {
    &:before,
    &:after {
      border-width: 1px;
    }

    &:before {
      border-color: ${({ theme }) => theme.palette.colors.neutral[200]};
    }
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-width: 1px;
  }

  .MuiInput-underline:before {
    border-color: ${({ theme }) => theme.palette.colors.neutral[200]};
  }

  .MuiFormHelperText-root {
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-family: 'Montserrat Regular';
    margin-top: ${({ theme }) => theme.spacing(1)}px;
  }
`
