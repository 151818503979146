import React, { FC } from 'react'
import { DialogProps } from './interfaces'
import { DialogStyled } from './styles'

const DialogExtended: FC<DialogProps> = ({
  classes = {},
  fullScreen = false,
  open,
  blockBackDropClick,
  onClose,
  scroll,
  ...props
}) => (
  <DialogStyled
    open={open}
    fullScreen={fullScreen}
    scroll={scroll}
    classes={{
      ...classes,
      scrollPaper: 'scrollPaper',
      paperFullWidth: 'paperFullWidth',
      paperScrollPaper: 'paperScrollPaper',
      paper: 'paper'
    }}
    onClose={(_, reason) => {
      if (!onClose || (blockBackDropClick && reason == 'backdropClick')) {
        return null
      }
      onClose()
    }}
    {...props}
  />
)

export default DialogExtended
