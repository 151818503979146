import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { TableBody, TableRow, TableHead, TableContainer } from '@material-ui/core'
import { Typography } from '..'
import { TableCellStyled, SimpleTableStyled } from './styles'
import { theme } from 'providers/Theme'

function SimpleTable({ columns, rows, minWidth }) {
  const getColumn = (data, i) => (
    <TableCellStyled key={`${i}-table-column`}>
      <Typography variant="body4" font="SemiBold">
        {data}
      </Typography>
    </TableCellStyled>
  )

  const renderColumns = () => columns.map(getColumn)

  const getRowData = (data, i) => (
    <TableCellStyled key={`${i}-table-row-data`}>
      <Typography variant="body4" color={theme.palette.colors.neutral[400]}>
        {data}
      </Typography>
    </TableCellStyled>
  )

  const getRow = (data, i) => (
    <TableRow className="pb-2" key={`${i}-table-row`}>
      {data.map(getRowData)}
    </TableRow>
  )

  const renderRows = () => rows.map(getRow)

  return (
    <TableContainer>
      <SimpleTableStyled aria-label="simple table" $minWidth={minWidth}>
        <TableHead>
          <TableRow>{renderColumns()}</TableRow>
        </TableHead>
        <TableBody>{renderRows()}</TableBody>
      </SimpleTableStyled>
    </TableContainer>
  )
}

SimpleTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  minWidth: PropTypes.string
}

export default memo(SimpleTable)
