import styled from 'styled-components'
import DialogTitle from '../DialogTitle'
import { DialogHeaderStyledProps } from './interfaces'

export const DialogHeaderStyled = styled(DialogTitle)<DialogHeaderStyledProps>`
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;
`

export const ActionsContainerStyled = styled.div`
  margin: ${({ theme }) => theme.spacing(-1, -2, -1, 1)};
  margin-left: auto;
  color: ${({ theme }) => theme.palette.colors.neutral[500]};
`
