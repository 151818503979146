import styled from 'styled-components'

export const ContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const NavStyled = styled.nav`
  width: 55px;
`

export const ToolbarPlaceHolderStyled = styled.div`
  padding-top: 59px;
  @media (min-width: 0px) and (orientation: landscape) {
    padding-top: 51px;
  }

  @media (min-width: 600px) {
    padding-top: 67px;
  }
`

export const MainStyled = styled.main`
  flex-grow: 1;
`
