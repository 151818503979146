import styled from 'styled-components'
import FormHelperText from '@material-ui/core/FormHelperText'

export default styled(FormHelperText)`
  position: absolute;
  bottom: -18px;
  height: 15px;
  line-height: 15px;
  text-overflow: ellipsis;
`
