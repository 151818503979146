import styled, { css } from 'styled-components'
import { ProposalItemSkeletonProps } from './interfaces'

export const ContainerStyled = styled.div<ProposalItemSkeletonProps>`
  padding: ${({ theme }) => theme.spacing(1, 1, 1, 3)};
  width: ${({ theme }) => theme.spacing(90)}px;
  height: ${({ theme }) => theme.spacing(9.5)}px;
  position: relative;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  justify-content: space-between;

  ${({ gutterBottom }) =>
    gutterBottom &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(3)}px;
    `}

  &::before {
    content: '';
    background-color: ${({ theme }) => theme.palette.colors.neutral[200]};
    width: 3px;
    border-radius: ${({ theme }) => theme.spacing(2.5)}px;
    height: ${({ theme }) => theme.spacing(7)}px;
    position: absolute;
    left: 0;
    top: ${({ theme }) => theme.spacing(1.25)}px;
  }
`

export const ItemsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
