import React, { Suspense, memo } from 'react'
import { useStoreState } from 'commons/hooks/useStore'
import errors from 'commons/constants/errors'
import { Route, Switch } from 'react-router-dom'
import { Roles } from '@base39/ui-utils'
import Routes from 'routes'
import { RouteProps } from 'routes/interfaces'
import Loading from 'components/Loading'
import ErrorPage from 'components/ErrorBoundary/sections/ErrorPage'
import { AppRoute } from './sections'
import { ContainerStyled } from './styles'

const Main = () => {
  const { user } = useStoreState(({ user: userState }) => userState)
  const routes = Roles.isRH(user) ? Routes.hrRoutes : Routes.employeeRoutes

  const getRoutes = (route: RouteProps) => {
    const key = route.path instanceof Array ? route.path[0] : route.path
    const routeAccessCondition = route.condition || 'DEFAULT'
    return <AppRoute key={key} {...route} condition={routeAccessCondition} />
  }

  return (
    <ContainerStyled>
      <Suspense fallback={<Loading />}>
        <Switch>
          {routes.map((route) => getRoutes(route))}
          <Route
            path="*"
            component={() => <ErrorPage errorObject={errors['PAGE_NOT_FOUND']} />}
          />
        </Switch>
      </Suspense>
    </ContainerStyled>
  )
}

export default memo(Main)
