import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from 'apollo-link-context'
import { Tenant } from '@base39/ui-utils'
import { getAuthToken } from 'commons/utils/auth'

const uri = `${process.env.REACT_APP_API3_URL}/graphql`

const fetcher = (...args) => {
  return window.fetch(...args)
}

const httpLink = new HttpLink({
  uri,
  fetcher
})

const authMiddleware = setContext(() => {
  const { token } = getAuthToken()

  return {
    headers: {
      access_token: token,
      authorization: token,
      'x-tenant-id': Tenant.getTenant()
    }
  }
})

const client = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false })
})

export default client
