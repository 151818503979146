import styled, { css } from 'styled-components'
import { TableBody, TableRow } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { Link } from 'components'

export const TableBodyStyled = styled(TableBody)`
  background: ${({ theme }) => theme.palette.colors.neutral[0]};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`
export const TableRowBodyStyled = styled(TableRow)`
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        background: ${({ theme }) => theme.palette.colors.neutral[50]};
      }
    `}
`

export const ImageStyled = styled.img`
  padding: 0 50px;
  width: 100%;
`

export const TextStyled = styled.p`
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
`

export const TextFieldStyled = styled(TextField)`
  width: 100%;
`

export const LinkStyled = styled(Link)`
  text-decoration: none;
`
