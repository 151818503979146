import i18n from 'commons/i18n'
import { IField } from '@flatfile/adapter/build/main/interfaces'

const requiredValidator = [{ validate: 'required', error: i18n.t('form.fieldRequired') }]

export const fileFields = [
  {
    key: 'name',
    label: 'Nome',
    alternates: ['Funcionário']
  },
  {
    key: 'email',
    label: 'Email',
    alternates: ['E-mail']
  },
  {
    key: 'cpf',
    label: 'CPF',
    validators: requiredValidator
  },
  {
    key: 'cnpj',
    label: 'CNPJ'
  },
  {
    key: 'phoneNumber',
    label: 'Celular',
    alternates: ['Telefone']
  },
  {
    key: 'birthdayDate',
    label: 'Data de nascimento'
  },
  {
    key: 'maritalStatus',
    label: 'Estado civil'
  },
  {
    key: 'consignableMargin',
    label: 'Margem Consignável'
  },
  {
    key: 'salary',
    label: 'Salário bruto'
  },
  {
    key: 'hiredAt',
    label: 'Data de admissão'
  },
  {
    key: 'zipCode',
    label: 'CEP'
  },
  {
    key: 'address',
    label: 'Logradouro'
  },
  {
    key: 'number',
    label: 'Número'
  },
  {
    key: 'complement',
    label: 'Complemento'
  },
  {
    key: 'neighborhood',
    label: 'Bairro'
  },
  {
    key: 'city',
    label: 'Cidade'
  },
  {
    key: 'state',
    label: 'Estado'
  },
  {
    key: 'bank',
    label: 'Banco'
  },
  {
    key: 'accountType',
    label: 'Tipo de conta',
    type: 'select',
    options: [
      {
        value: 'Corrente',
        label: 'Corrente'
      },
      {
        value: 'Poupança',
        label: 'Poupança'
      }
    ]
  },
  {
    key: 'agency',
    label: 'Agência'
  },
  {
    key: 'account',
    label: 'Conta'
  },
  {
    key: 'accountDigit',
    label: 'Dígito'
  },
  {
    key: 'rg',
    label: 'RG'
  },
  {
    key: 'rgEmitter',
    label: 'Emissor do RG',
    alternates: ['SSP']
  },
  {
    key: 'rgState',
    label: 'Estado do RG',
    alternates: ['UF']
  },
  {
    key: 'netSalary',
    label: 'Salário líquido'
  },
  {
    key: 'nationality',
    label: 'Nacionalidade'
  },
  {
    key: 'native',
    label: 'Naturalidade'
  },
  {
    key: 'gender',
    label: 'Gênero',
    alternates: ['Sexo']
  },
  {
    key: 'jobRole',
    label: 'Cargo'
  }
] as IField[]
