import styled from 'styled-components'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import Typography from '../Typography'

export const AccordionStyled = styled(Accordion)`
  box-shadow: none;
`

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: 0;
  justify-content: left;

  .MuiAccordionSummary-content {
    flex-grow: inherit;

    &.Mui-expanded {
      margin: 0;
    }
  }

  &.Mui-expanded {
    min-height: 48px;

    .MuiAccordionSummary-expandIcon {
      transform: rotate(90deg);
    }
  }
`

export const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0;
`

export const AccordionContentStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const AccordionTitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
`
