import React, { useState, useEffect, useCallback } from 'react'
import 'react-datasheet/lib/react-datasheet.css'
import Datasheet from './VirtualizedDatasheet'
import { Number as NumberUtils } from '@base39/ui-utils'
import debounce from 'debounce'

const DataSheet = ({
  rows,
  headers,
  onCellsSelected,
  skipRender,
  isRowLoaded,
  loadMoreRows,
  rowCount,
  onLetterClick
}) => {
  const [grid, setGrid] = useState([])

  const normalizeToDataSheet = useCallback(
    (row) =>
      Object.keys(row)
        .filter((key) => !skipRender.includes(key))
        .map((key) => {
          const newValue = row[key]
          return { value: newValue }
        }),
    [skipRender]
  )

  const renderData = useCallback(() => {
    return rows ? rows.map(normalizeToDataSheet) : []
  }, [rows, normalizeToDataSheet])

  useEffect(() => {
    setGrid(renderData())
  }, [rows, renderData])

  const valueRenderer = (cell) => cell.value

  const onCellsChanged = (changes) => {
    const newGrid = grid
    changes.forEach(({ row, col, value }) => {
      newGrid[row][col] = { ...newGrid[row][col], value }
    })
    setGrid(newGrid)
  }

  const onContextMenu = (e, cell) => (cell.readOnly ? e.preventDefault() : null)

  const CURRENCY = 'R$'

  const getValue = (point) =>
    point?.value && NumberUtils.convertCurrencyToNumber(point.value, CURRENCY)

  const sumValues = (values) =>
    values.reduce((total, column) => {
      const getTotal = total
      const value = column
      return getTotal + value
    })

  const handleOnCellsSelected = debounce(({ start, end }) => {
    const valuesToSum = []

    grid.forEach((item, index) => {
      if (NumberUtils.inRange(index, start.i, end.i)) {
        item.forEach((subItem, subItemIndex) => {
          if (!NumberUtils.inRange(subItemIndex, start.j, end.j)) {
            return
          }

          const value = getValue(subItem)

          if (!value) {
            return
          }

          valuesToSum.push(value)
        })
      }
    })

    const sum = valuesToSum.length < 1 ? 0 : sumValues(valuesToSum)

    onCellsSelected({ sum })
  }, 500)

  return (
    <>
      <Datasheet
        headers={headers}
        data={grid}
        rows={rows}
        onLetterClick={onLetterClick}
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={rowCount}
        valueRenderer={valueRenderer}
        onContextMenu={onContextMenu}
        onCellsChanged={onCellsChanged}
        onSelect={handleOnCellsSelected}
      />
    </>
  )
}

DataSheet.defaultProps = {
  headers: [],
  rows: [],
  rowsPerPage: 10,
  sort: '-createdAt',
  page: 0,
  count: 0,
  loading: false,
  onRowSelected: undefined,
  hasPagination: true,
  size: 'medium',
  padding: 'normal',
  wordBreak: 'break-word',
  maxHeight: undefined,
  elevation: 1
}

export default DataSheet
