import React, { FC, memo, useState } from 'react'
import Uppy from '@uppy/core'
import { DragDrop, useUppy } from '@uppy/react'
import { WrapperStyled } from '../../styles'
import { ImportEmployeesProps, SendFileToPreSignedUrl } from './interfaces'
import { defaultOptions } from '../../utils'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import toast from 'commons/utils/toast'
import i18n from 'commons/i18n'
import { UPPY_ERROR_TYPES } from 'commons/constants/uppy'
import { onResponseError } from 'resources/api/interceptor'
import { useMutation } from 'react-query'
import EmployeesBase from 'resources/services/employees-base'
import { EmployeeResponse } from 'resources/services/employees-base/interface'

const ImportEmployees: FC<ImportEmployeesProps> = ({
  children,
  className,
  onDragLeave,
  onDragOver,
  onUpload,
  onUploadSuccess,
  onUploadError
}) => {
  const [objectKey, setObjectKey] = useState('')

  const { mutate: getPreSignedUrl } = useMutation(EmployeesBase.getPreSignedUrl, {
    onSuccess: async (response) => {
      if (onUpload) {
        onUpload()
      }

      const file = uppy.getFiles()[0]

      const contentType = file.type || 'text/csv'

      setObjectKey(response.filename)

      const fileData = new Blob([file.data], { type: contentType })

      sendFileToPreSignedUrl({
        fileData,
        preSignedUrl: response.preSignedUrl,
        contentType
      })
    }
  })

  const { mutate: sendFileToPreSignedUrl } = useMutation(
    (values: SendFileToPreSignedUrl) =>
      EmployeesBase.sendFileToPreSignedUrl(
        values.preSignedUrl,
        values.fileData,
        values.contentType
      ),
    {
      onSuccess: () => {
        sendObjectKey(objectKey)
      }
    }
  )

  const { mutate: sendObjectKey } = useMutation(
    'sendObjectKey',
    (objectKey: string) => EmployeesBase.sendObjectKey(objectKey),
    {
      onSuccess: (response: EmployeeResponse) => {
        if (onUploadSuccess) {
          onUploadSuccess(response)
        }
      },
      onError: (response: EmployeeResponse) => {
        if (response?.response?.data?.code !== 'INVALID_FILE_CONTENT') {
          const axiosError = {
            response: {
              data: response?.id,
              status: response?.status
            }
          }

          onResponseError(axiosError)
        }

        response?.response?.data?.code === 'INVALID_FILE_CONTENT' &&
          toast.error({
            message: i18n.t('companyPortal.validations.errorFileContent')
          })

        if (onUploadError) {
          onUploadError()
        }
      }
    }
  )

  const uppy = useUppy(() =>
    new Uppy({
      ...defaultOptions,
      locale: {
        strings: UPPY_ERROR_TYPES
      },
      restrictions: {
        allowedFileTypes: ['.csv', '.xls', '.xlsx'],
        maxFileSize: 10 * 1024 * 1024,
        maxNumberOfFiles: 1
      }
    })
      .on('file-added', (file) => {
        getPreSignedUrl({ filename: file.name, contentType: file.type || 'text/csv' })
      })
      .on('restriction-failed', (file, error) => {
        if (error.message === UPPY_ERROR_TYPES.exceedsSize) {
          toast.error({
            message: i18n.t('companyPortal.validations.errorInvalidFileSize')
          })
        }

        if (error.message === UPPY_ERROR_TYPES.youCanOnlyUploadFileTypes) {
          toast.error({
            message: i18n.t('companyPortal.validations.errorInvalidFileFormat')
          })
        }

        if (onUploadError) {
          onUploadError()
        }
      })
  )

  return (
    <button className={className}>
      <WrapperStyled>
        <DragDrop
          id="import-employee-drag-drop"
          data-testid="import-employee-drag-drop"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          uppy={uppy}
        />
      </WrapperStyled>
      {children}
    </button>
  )
}

export default memo(ImportEmployees)
