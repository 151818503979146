import styled, { css } from 'styled-components'
import { BurgerStyledProps } from './interfaces'

export const BurgerStyled = styled.div<BurgerStyledProps>`
  width: ${({ theme }) => theme.spacing(3)}px;
  height: ${({ theme }) => theme.spacing(3)}px;
  margin-left: ${({ theme }) => theme.spacing(4)}px;
  display: none;
  cursor: pointer;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      display: flex;
      justify-content: space-around;
      flex-flow: column nowrap;
    }
  `}

  div {
    width: ${({ theme }) => theme.spacing(3)}px;
    height: ${({ theme }) => theme.spacing(0.5)}px;
    background-color: ${({ isActive }) =>
      isActive
        ? ({ theme }) => theme.palette.secondary.main
        : ({ theme }) => theme.palette.primary.main};
    border-radius: ${({ theme }) => theme.spacing(1)}px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ isActive }) => (isActive ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ isActive }) => (isActive ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ isActive }) => (isActive ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ isActive }) => (isActive ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`
