import React, { useState } from 'react'
import { Accordion } from 'components'
import i18n from 'commons/i18n'

const DataDisplay = ({ children }) => {
  const [expanded, setExpanded] = useState(false)

  const handleExpandedAccordion = () => setExpanded(!expanded)

  const getTitle = !expanded ? i18n.t('general.seeMore') : i18n.t('general.seeLess')

  return (
    <Accordion onChange={handleExpandedAccordion} expanded={expanded} title={getTitle}>
      {children}
    </Accordion>
  )
}

export default DataDisplay
