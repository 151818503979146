import { css } from 'styled-components'
import { DefaultStylesProps } from './interfaces'

export const getDefaultStyles = ({
  color,
  defaultStyle,
  contrasted,
  defaultFont,
  font,
  lineHeight,
  linked,
  italic,
  opacity,
  textAlign,
  fullWidth
}: DefaultStylesProps) => css`
  text-decoration: none;
  margin: 0;
  color: ${({ theme }) =>
    contrasted
      ? theme.palette.colors.neutral[0]
      : color || theme.palette.colors.neutral[500]};
  font-family: Montserrat ${font || defaultFont};

  ${defaultStyle}

  ${opacity &&
  css`
    opacity: ${opacity};
  `}

  ${lineHeight &&
  css`
    line-height: ${lineHeight / 16}rem !important;
  `}

  ${linked &&
  css`
    text-decoration: underline;
    cursor: pointer;
  `}

  ${italic &&
  css`
    font-style: italic;
  `}

  ${textAlign &&
  css`
    text-align: ${textAlign};
  `}

  ${fullWidth &&
  css`
    width: 100%;
  `}
`
