import React from 'react'
import { BurgerButtonComponent } from './interfaces'
import { BurgerStyled } from './styles'

const BurgerButton: BurgerButtonComponent = ({ isActive, onClick }) => {
  return (
    <BurgerStyled id="menu-burger-button" isActive={isActive} onClick={onClick}>
      <div id="burger-div" />
      <div />
      <div />
    </BurgerStyled>
  )
}

export default BurgerButton
