import { Action, action } from 'easy-peasy'
import { Auth, User } from 'interfaces/user'

export interface UserModel {
  hasDisplayedProfessionalPage: boolean
  renderedLoginMessage: boolean
  user: User | null
  auth: Auth
  setRenderedLoginMessage: Action<UserModel, boolean>
  setHasDisplayedProfessionalPage: Action<UserModel, boolean>
  setUser: Action<UserModel, User>
  setAuth: Action<UserModel, Auth>
  clearUserData: Action<UserModel>
}

const user: UserModel = {
  hasDisplayedProfessionalPage: false,
  renderedLoginMessage: false,
  user: null,
  auth: {
    isAuthenticated: false
  },
  setHasDisplayedProfessionalPage: action((state, payload) => {
    state.hasDisplayedProfessionalPage = payload
  }),
  setRenderedLoginMessage: action((state, payload) => {
    state.renderedLoginMessage = payload
  }),
  setUser: action((state, payload) => {
    state.user = payload
  }),
  setAuth: action((state, payload) => {
    state.auth = payload
  }),
  clearUserData: action((state) => {
    state.user = null
    state.auth = {
      isAuthenticated: false
    }
    state.renderedLoginMessage = false
    state.hasDisplayedProfessionalPage = false
  })
}

export default user
