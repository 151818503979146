import React from 'react'
import PropTypes from 'prop-types'
import { withField } from '../Field'
import { onKeyDownNumber, onPasteNumber } from '../utils'
import { MuiInputStyled } from './style'
import { InputProps } from '@material-ui/core'

const getInputProps = ({ placeholder, type, inputProps }: InputProps) => {
  const props = {
    'aria-label': placeholder,
    ...inputProps
  }

  if (type === 'number') {
    return {
      onPaste: onPasteNumber,
      onKeyDown: onKeyDownNumber,
      ...props
    }
  }

  return props
}

const Input = (props: InputProps) => (
  <MuiInputStyled {...props} inputProps={getInputProps(props)} />
)

Input.defaultProps = {
  type: 'text',
  inputProps: {}
}

Input.propTypes = {
  type: PropTypes.string,
  inputProps: PropTypes.object
}

export { Input }
export default withField(Input)
