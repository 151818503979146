import React from 'react'
import ReactDataSheet from 'react-datasheet'
import PropTypes from 'prop-types'
import { MultiGrid, AutoSizer, InfiniteLoader } from 'react-virtualized'
import {
  WrapperStyled,
  CellWrapperStyled,
  HeaderStyled,
  HeaderItemStyled,
  RelativeWrapperStyled,
  LoadingWrapperStyled,
  OverflowWrapperStyled
} from './style'
import DataCell from 'react-datasheet/lib/DataCell'
import { theme } from 'providers/Theme'
import i18n from 'commons/i18n'
import { Typography } from 'components'

class DataSheet extends ReactDataSheet {
  _setState(state) {
    super._setState(state)
    this.grid.forceUpdateGrids()
  }

  getColumnWidth = ({ index }) => {
    const { columnsWidth } = this.props
    return this.transformColumnsWidth(columnsWidth)[index]
  }

  transformColumnsWidth = (columnsWidth) => {
    const { breakpoints } = theme

    return columnsWidth.map((width) => {
      const options = {
        [window.innerWidth >= breakpoints.values.md]: -20,
        [window.innerWidth >= breakpoints.values.lg]: 0,
        [window.innerWidth >= 1680]: 25,
        [window.innerWidth >= breakpoints.values.xl]: 45
      }

      const offset = options.true || 0
      return width + offset
    })
  }

  infiniteLoaderChildFunction = ({ onRowsRendered, registerChild, sheetHeight }) => {
    const {
      attributesRenderer,
      cellRenderer,
      valueRenderer,
      dataRenderer,
      valueViewer,
      dataEditor,
      data,
      bolderColumns,
      columnsWidth
    } = this.props

    const width = this.transformColumnsWidth(columnsWidth).reduce((a, b) => a + b, 0)

    return (
      <MultiGrid
        onSectionRendered={({ rowStartIndex, rowStopIndex }) => {
          const startIndex = rowStartIndex
          const stopIndex = rowStopIndex

          onRowsRendered({
            startIndex,
            stopIndex
          })
        }}
        ref={(ref) => {
          this.grid = ref
          registerChild(ref)
        }}
        cellRenderer={({ key, rowIndex, columnIndex, style }) => (
          <CellWrapperStyled
            isBolder={bolderColumns.includes(columnIndex)}
            key={key}
            style={style}
          >
            <DataCell
              row={rowIndex}
              col={columnIndex}
              cell={data[rowIndex][columnIndex]}
              forceEdit={this.state.forceEdit}
              onMouseDown={this.onMouseDown}
              onMouseOver={this.onMouseOver}
              onDoubleClick={this.onDoubleClick}
              onContextMenu={this.onContextMenu}
              onChange={this.onChange}
              onRevert={this.onRevert}
              onNavigate={this.handleKeyboardCellMovement}
              onKey={this.handleKey}
              selected={this.isSelected(rowIndex, columnIndex)}
              editing={this.isEditing(rowIndex, columnIndex)}
              clearing={this.isClearing(rowIndex, columnIndex)}
              attributesRenderer={attributesRenderer}
              cellRenderer={cellRenderer}
              valueRenderer={valueRenderer}
              dataRenderer={dataRenderer}
              valueViewer={valueViewer}
              dataEditor={dataEditor}
            />
          </CellWrapperStyled>
        )}
        overscanRowCount={5}
        columnWidth={this.getColumnWidth}
        columnCount={data[0].length}
        height={sheetHeight}
        rowHeight={55}
        rowCount={data.length}
        width={width}
        hideTopRightGridScrollbar
        hideBottomLeftGridScrollbar
      />
    )
  }

  renderHeaderItem = (header, index) => {
    const { columnsWidth } = this.props
    return (
      <HeaderItemStyled
        width={this.transformColumnsWidth(columnsWidth)[index]}
        key={header.label}
      >
        {header.label}
      </HeaderItemStyled>
    )
  }

  renderTableData = () => {
    const { isRowLoaded, loadMoreRows, rowCount } = this.props
    return (
      <WrapperStyled
        ref={(r) => {
          this.dgDom = r
        }}
        tabIndex="0"
        className="data-grid-container"
        onKeyDown={this.handleKey.bind(this)}
      >
        <AutoSizer>
          {({ width: sheetWidth, height: sheetHeight }) => (
            <div className="data-grid">
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={rowCount}
              >
                {({ onRowsRendered, registerChild }) =>
                  this.infiniteLoaderChildFunction({
                    onRowsRendered,
                    registerChild,
                    sheetHeight,
                    sheetWidth
                  })
                }
              </InfiniteLoader>
            </div>
          )}
        </AutoSizer>
      </WrapperStyled>
    )
  }

  render() {
    const { data, headers, columnsWidth } = this.props

    const maxWidth = this.transformColumnsWidth(columnsWidth).reduce((a, b) => a + b, 0)

    return (
      <OverflowWrapperStyled maxWidth={maxWidth}>
        <RelativeWrapperStyled>
          <HeaderStyled>{headers.map(this.renderHeaderItem)}</HeaderStyled>
          {data.length ? (
            this.renderTableData()
          ) : (
            <LoadingWrapperStyled>
              <Typography variant="h5">{i18n.t('payment.notFound')}</Typography>
            </LoadingWrapperStyled>
          )}
        </RelativeWrapperStyled>
      </OverflowWrapperStyled>
    )
  }
}

DataSheet.propTypes = {
  ...DataSheet.propTypes,
  sheetRenderer: PropTypes.any,
  rowRenderer: PropTypes.any
}

export default DataSheet
DataSheet.defaultProps = {
  ...DataSheet.defaultProps,
  sheetRenderer: null,
  rowRenderer: null,
  columnsWidth: [180, 140, 100, 100, 110, 100],
  bolderColumns: [0],
  headers: [],
  cellRenderer: ({
    children,
    cell,
    row,
    col,
    attributesRenderer,
    className,
    style,
    onMouseDown,
    onMouseOver,
    onDoubleClick,
    onContextMenu
  }) => (
    <div
      className={className}
      onMouseDown={onMouseDown}
      onMouseOver={onMouseOver}
      onDoubleClick={onDoubleClick}
      onContextMenu={onContextMenu}
      style={style}
      {...(!attributesRenderer ? {} : attributesRenderer(cell, row, col))}
    >
      {children}
    </div>
  )
}
