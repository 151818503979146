import styled from 'styled-components'

export const WrapperStyled = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  > div {
    height: 100%;
    width: 100%;
    padding: 0;

    > button {
      background: transparent;
      border: 0;

      > * {
        display: none;
      }
    }
  }
`
