import React, { FC } from 'react'
import i18n from 'commons/i18n'
import { theme } from 'providers/Theme'
import { Typography } from 'components'
import { LoadingProps } from './interfaces'
import { LoadingStyled, ContainerStyled, CircularIconStyled, LogoStyled } from './styles'

const Loading: FC<LoadingProps> = ({
  full = false,
  className,
  width = '100%',
  height = '100%',
  Icon,
  children
}) => {
  return (
    <LoadingStyled
      $full={full}
      className={className}
      $width={width}
      $height={height}
      aria-label={i18n.t('form.loading')}
    >
      <ContainerStyled>
        <CircularIconStyled />
        <LogoStyled hasIcon={!!Icon}>
          {Icon ? (
            <Icon />
          ) : (
            <img src={theme.logo.short} alt={`${i18n.t('seo.logo.alt')}`} />
          )}
        </LogoStyled>
      </ContainerStyled>
      {children && (
        <Typography
          variant="body3"
          font="Bold"
          color={theme.palette.primary.main}
          className="mt-5 ph-2"
        >
          {children}
        </Typography>
      )}
    </LoadingStyled>
  )
}

export default Loading
