import React, { memo } from 'react'
import { LinearProgressStyled } from './styles'
import { ProgressProps } from './interface'
import { theme } from 'providers/Theme'

const Progress = ({
  value,
  $maxWidth = '338',
  $backgroundColor = theme.palette?.primary.main
}: ProgressProps) => {
  return (
    <LinearProgressStyled
      variant={value ? 'determinate' : 'indeterminate'}
      value={value}
      $maxWidth={$maxWidth}
      $backgroundColor={$backgroundColor}
    />
  )
}

export default memo(Progress)
