import { CNPJ as CNPJUtils } from '@base39/ui-utils'
import i18n from 'commons/i18n'
import { Company } from 'layouts/CompanyPortal/interfaces'
import { User, ImportedDataProps } from './interfaces'

export const formatUsersToImport = ({
  companies,
  users
}: {
  companies: Company[]
  users: User[]
}) => {
  if (!companies.length) {
    return [
      {
        id: '',
        tradeName: '',
        cnpj: '',
        importStatus: 'default',
        importOption: 'add',
        users
      }
    ]
  }

  const importData: ImportedDataProps[] = []

  users.forEach((user) => {
    Object.keys(user).forEach((key) => {
      if (user[key] === null) {
        delete user[key]
      }

      if (key === 'accountType' && user.accountType) {
        user[key] = user[key] === 'Corrente' ? 'current' : 'savings'
      }
    })
    let counter = 0
    importData.forEach((data) => {
      if (user.cnpj === data.cnpj) {
        delete user.cnpj

        data.users.push(user)
        counter++
      }
    })
    if (counter === 0) {
      const cnpj = user.cnpj
      const [matchedCompany] = companies.filter(
        (company) => company.cnpj === CNPJUtils.strip(cnpj)
      )

      if (matchedCompany) {
        const { id, tradeName } = matchedCompany
        delete user.cnpj
        importData.push({
          id,
          tradeName,
          cnpj,
          importStatus: 'default',
          importOption: 'add',
          users: [user]
        })
      }
    }
  })

  return importData
}

export const formatResponseMessageAndStatus = (response) => {
  let status = 'error'
  let message = i18n.t('employee.userImport.error')

  if (response.includes('Failed to fetch')) {
    message = i18n.t('employee.userImport.maxLimitError')
  }

  if (response.includes('Request is timed out')) {
    status = 'default'
    message = i18n.t('employee.userImport.importIsRunning')
  }

  return {
    message,
    status
  }
}

const canImportUsers = (importStatus?: string, message?: string): boolean =>
  importStatus === 'loading' ||
  (importStatus === 'default' && !message) ||
  importStatus === 'error'

export const formatDataToImportUsers = (importedData: ImportedDataProps[]) => {
  return importedData.map((data) => {
    const newData = { ...data }

    if (canImportUsers(newData.importStatus, newData.message)) {
      newData.importStatus = 'loading'
      newData.message = undefined

      if (!newData?.id) {
        newData.importStatus = 'error'
      }
    }

    return newData
  })
}

export const canFinishImport = (importedData: ImportedDataProps[]): boolean =>
  importedData.filter(
    (data) =>
      data.importStatus === 'success' || (data.importStatus === 'default' && data.message)
  ).length === importedData.length
