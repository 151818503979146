import React, { memo } from 'react'
import i18n from 'commons/i18n'
import { Object as ObjectUtils } from '@base39/ui-utils'
import { TableRow, TableCell } from '@material-ui/core'
import { theme } from 'providers/Theme'
import { Typography, Tooltip } from 'components'
import {
  TableRowBodyStyled,
  TableBodyStyled,
  TableContainerStyled,
  PaperWrapTableStyled,
  TableCellStyled,
  TableHeadStyled
} from './styles'

const Table = ({ headers, rows, padding, elevation = 1, width = '100%' }) => {
  const renderBodyRowContent = (row, index) => (
    <TableRowBodyStyled key={index}>
      {headers.map(({ value }, headerIndex) => (
        <TableCellStyled key={`table-cell-styled-${headerIndex}`} align="left">
          <Typography variant="body3" font="Regular">
            {row.renderContent?.(row, value) || ObjectUtils.get(row, value)}
          </Typography>
        </TableCellStyled>
      ))}
    </TableRowBodyStyled>
  )

  const renderBodyRow = (row, index) =>
    row.tooltip ? (
      <Tooltip key={`tooltip-${index}`} title={row.tooltip} placement="top">
        {renderBodyRowContent(row, index)}
      </Tooltip>
    ) : (
      renderBodyRowContent(row, index)
    )

  return (
    <>
      <PaperWrapTableStyled elevation={elevation}>
        <TableContainerStyled width={width} padding={padding}>
          <TableHeadStyled>
            <TableRow>
              {headers.map(({ icon: Icon, label }, index) => (
                <TableCell key={`table-cell-${index}`} align="left">
                  {label ? <Typography variant="body3">{label}</Typography> : <Icon />}
                </TableCell>
              ))}
            </TableRow>
          </TableHeadStyled>
          {rows.length ? (
            <TableBodyStyled>{rows.map(renderBodyRow)}</TableBodyStyled>
          ) : (
            <TableBodyStyled>
              <TableRow>
                <TableCell colSpan={headers.length}>
                  <div className="p-4" text-align="center">
                    <Typography variant="body3" color={theme.palette.colors.neutral[500]}>
                      {i18n.t('general.empty')}
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableBodyStyled>
          )}
        </TableContainerStyled>
      </PaperWrapTableStyled>
    </>
  )
}

export default memo(Table)
