import React, { FC } from 'react'
import { Route } from 'react-router-dom'
import { PrivateRouteProps } from './interfaces'

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  path,
  exact,
  location
}) => (
  <Route
    path={path}
    exact={exact}
    location={location}
    render={(routeProps) => <Component {...routeProps} />}
  />
)

export default PrivateRoute
