import styled, { css } from 'styled-components'
import { CardStyledProps } from './interfaces'

export const CardStyled = styled.div<CardStyledProps>`
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ?? theme.palette.colors.neutral[50]};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  align-items: center;
  padding: ${({ padding, theme }) => theme.spacing(padding)}px;
  z-index: 1;
  ${({ $pointer }) =>
    $pointer &&
    css`
      cursor: pointer;
    `}
`
