import React, { memo, FC } from 'react'
import i18n from 'commons/i18n'
import { theme } from 'providers/Theme'
import { Link } from 'components'
import { useLogout } from 'commons/hooks'
import { NavLinks } from './sections'
import { HeaderProps } from './interfaces'
import {
  HeaderContentStyled,
  HeaderContainerStyled,
  LogoStyled,
  WrapperStyled
} from './styles'

const Header: FC<HeaderProps> = ({ $isFully }) => {
  const { pathname } = window.location
  const logout = useLogout()

  return (
    <HeaderContainerStyled $isFully={$isFully}>
      <HeaderContentStyled $isFully={$isFully}>
        <WrapperStyled>
          <Link to="/produtos">
            <LogoStyled
              src={$isFully ? theme['logo'].main : theme['logo'].white}
              alt={`${i18n.t('seo.logo.alt')}`}
            />
          </Link>
          <NavLinks onLogout={logout} pathname={pathname} $isFully={$isFully} />
        </WrapperStyled>
      </HeaderContentStyled>
    </HeaderContainerStyled>
  )
}

export default memo(Header)
