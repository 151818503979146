import { useQuery } from 'react-query'
import toast from 'commons/utils/toast'
import i18n from 'commons/i18n'
import CreditLinesApi from 'resources/services/credit-lines'

export const useGetPaymentsRequest = (id: string) => {
  return useQuery(['GetPayments', id], () => CreditLinesApi.getPayments(id), {
    enabled: !!id,
    onError: () => {
      toast.error({ message: i18n.t('payment.errors.failRequest') })
    }
  })
}
