import advance from './advance.json'
import approval from './approval.json'
import causesOfRejection from './causes-of-rejection.json'
import ccb from './ccb.json'
import company from './company.json'
import employee from './employee.json'
import errorBoundary from './errorBoundary.json'
import form from './form.json'
import general from './general.json'
import header from './header.json'
import companyPortal from './companyPortal.json'
import payment from './payment.json'
import payroll from './payroll.json'
import proposal from './proposal.json'
import seo from './seo.json'
import signup from './signup.json'
import user from './user.json'
import invoices from './invoices.json'
import advanceFgts from './advanceFgts.json'
import apiErrors from './api-errors.json'

export default {
  advanceFgts,
  advance,
  apiErrors,
  approval,
  causesOfRejection,
  ccb,
  company,
  employee,
  errorBoundary,
  form,
  general,
  header,
  companyPortal,
  payment,
  payroll,
  proposal,
  seo,
  signup,
  user,
  invoices
}
