import styled, { css } from 'styled-components'
import { LoadingStyledProps, LogoStyledProps } from './interfaces'

export const LoadingStyled = styled.div<LoadingStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.palette?.primary.main};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};

  ${({ $full }) =>
    $full &&
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: ${({ theme }) => theme.palette?.colors.neutral[0]};
      z-index: 999;
    `};
`

export const ContainerStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LogoStyled = styled.div<LogoStyledProps>`
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;

  svg,
  img {
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
  }

  ${({ hasIcon, theme }) =>
    hasIcon &&
    css`
      path {
        fill: ${theme.palette.primary.main};
      }
    `}
`
export const CircularIconStyled = styled.div`
  animation: spin 0.7s linear infinite;
  height: 90px;
  width: 90px;
  border-color: ${({ theme }) => `
    ${theme.palette?.primary.light} ${theme.palette?.primary.light} ${theme.palette?.primary.light} ${theme.palette?.primary.main}
  `};
  border-width: 8px;
  border-style: solid;
  border-radius: 50%;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  position: absolute;
`
