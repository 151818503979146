import React, { Component } from 'react'
import { ErrorBoundaryState, ErrorBoundaryProps, CustomError } from './interfaces'
import ErrorPage from './sections/ErrorPage'
import errors from 'commons/constants/errors'

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      error: null
    }
  }

  static getDerivedStateFromError(err: CustomError) {
    const errorCode = err?.response?.data?.code || err?.message
    const errorObject = errors[errorCode || ''] || errors['DEFAULT']
    return { error: errorObject }
  }

  render() {
    if (this.state.error) {
      return <ErrorPage errorObject={this.state.error} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
