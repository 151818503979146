import { ReactComponent as ProjectsIcon } from 'assets/svg/projects.svg'
import { ReactComponent as CloseCircleIcon } from 'assets/svg/close_circle.svg'
import { ReactComponent as AlertIcon } from 'assets/svg/alert.svg'
import i18n from 'commons/i18n'

export const toastsData = {
  success: {
    title: i18n.t('general.toast.success'),
    Icon: ProjectsIcon
  },
  error: {
    title: i18n.t('general.toast.error'),
    Icon: CloseCircleIcon
  },
  warn: {
    title: i18n.t('general.toast.warn'),
    Icon: AlertIcon
  },
  info: {
    title: i18n.t('general.toast.info'),
    Icon: AlertIcon
  }
}
