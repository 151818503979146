import React, { FC, memo } from 'react'
import ArrowRightIcon from 'assets/svg/arrow-right.svg'
import {
  ContentStyled,
  LeftContentStyled,
  RightContentStyled,
  BlockStyled,
  ArrowRightIconStyled
} from './style'
import { FlatCardProps } from './interfaces'
import Typography from '../Typography'
import { theme } from 'providers/Theme'

const FlatCard: FC<FlatCardProps> = ({
  children,
  color,
  leftSide,
  rightSide,
  onClick,
  emptyRight,
  hasImportButton = false
}) => {
  const renderLeftSide = () => {
    const { title, info, label } = leftSide ?? {}
    return (
      <LeftContentStyled onClick={onClick}>
        <Typography variant="body3" font="Medium" color={color}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          font="SemiBold"
          color={theme.palette.colors.neutral[600]}
        >
          {info}
        </Typography>
        <Typography
          variant="body3"
          font="Regular"
          color={theme.palette.colors.neutral[500]}
        >
          {label}
        </Typography>
      </LeftContentStyled>
    )
  }

  const renderRightSide = () => {
    const { date, info, label } = rightSide ?? {}
    return (
      <RightContentStyled>
        <Typography variant="body3">{date}</Typography>
        <Typography variant="body2">{info}</Typography>
        <Typography variant="body3">{label}</Typography>
      </RightContentStyled>
    )
  }

  const renderArrowRight = () => {
    if (emptyRight) {
      return
    }

    return <ArrowRightIconStyled src={ArrowRightIcon} alt="fb" width="8" />
  }

  return (
    <ContentStyled color={color}>
      {renderLeftSide()}
      <BlockStyled onClick={!hasImportButton ? onClick : () => {}}>
        {rightSide && renderRightSide()}
        {children || renderArrowRight()}
      </BlockStyled>
    </ContentStyled>
  )
}

export default memo(FlatCard)
