import styled from 'styled-components'
import { DialogTitle, DialogContent, DialogActions } from 'components/Dialog'
import { ReactComponent as DownloadIcon } from 'assets/svg/download.svg'

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const DialogTitleStyled = styled(DialogTitle)`
  padding-top: 0;
  padding-bottom: ${({ theme }) => `${theme.spacing(4)}`}px;
  border-bottom: 0;
`

export const DialogContentStyled = styled(DialogContent)`
  padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(8)}px 0`};
`

export const DialogActionsStyled = styled(DialogActions)`
  display: flex;
  flex-direction: column;
`

export const DownloadIconStyled = styled(DownloadIcon)`
  path {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`
