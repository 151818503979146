import LinearProgress from '@material-ui/core/LinearProgress'
import styled from 'styled-components'
import { ProgressProps } from './interface'

export const LinearProgressStyled = styled(LinearProgress)<ProgressProps>`
  background: ${({ theme }) => theme.palette?.colors.neutral[100]};
  border-radius: 16px;
  height: 8px;
  max-width: ${({ $maxWidth }) => $maxWidth}px;

  .MuiLinearProgress-bar {
    background: ${({ $backgroundColor }) => $backgroundColor};
  }
`
