import { MIME_SIGNATURES } from '@base39/constants-tools'
import UppyApi from 'resources/services/uppy'
import i18n from 'commons/i18n'
import toast from 'commons/utils/toast'
import { defaultOptions } from '../../utils'

export const getUploadParameters = (key: string, type: string, cpf?: string): any => {
  if (!cpf) {
    toast.error({ message: i18n.t('proposal.error') })
    return
  }

  return UppyApi.post({
    bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    contentType: type,
    key
  }).then((response) => ({
    method: response.method,
    url: response.url,
    fields: [],
    headers: {
      'Content-Type': type
    }
  }))
}

export const validateMimeTypeFromBase64 = (base64: string, fileType = '') => {
  const sanitizedBase64 = base64.replace(base64.substring(0, base64.indexOf(',') + 1), '')
  let mimeFileType = ''

  MIME_SIGNATURES[fileType]?.forEach((signature: string) => {
    if (sanitizedBase64.indexOf(signature) === 0) {
      mimeFileType = fileType
    }
  })

  return fileType === mimeFileType
}

export { defaultOptions }
