import Typography from 'components/Typography'
import React, { FC } from 'react'
import { ToastProps } from './interfaces'
import { WrapperStyled } from './styles'
import { theme } from 'providers/Theme'
import { toastsData } from './utils'

const Toast: FC<ToastProps> = ({ message, type, titleName }) => {
  const { title, Icon } = toastsData[type]

  return (
    <WrapperStyled type={type}>
      <Icon />
      <Typography
        variant="h6"
        lineHeight={30}
        font="SemiBold"
        color={
          type === 'warn'
            ? theme.palette.colors.neutral[500]
            : theme.palette.primary.contrastText
        }
      >
        {titleName || title}
      </Typography>
      <Typography
        variant="body3"
        font="Regular"
        color={
          type === 'warn'
            ? theme.palette.colors.neutral[500]
            : theme.palette.primary.contrastText
        }
      >
        {message}
      </Typography>
    </WrapperStyled>
  )
}

export default Toast
