import { Action, action } from 'easy-peasy'
import { Product } from 'interfaces/creditLine'

export interface PayrollModel {
  data: Product | null
  setData: Action<PayrollModel, Product>
  clearPayrollData: Action<PayrollModel>
}

const payroll: PayrollModel = {
  data: null,
  setData: action((state, payload) => {
    state.data = payload
  }),
  clearPayrollData: action((state) => {
    state.data = null
  })
}

export default payroll
