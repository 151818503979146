import React, { FC, memo } from 'react'
import { CNPJ as CNPJUtils } from '@base39/ui-utils'
import i18n from 'commons/i18n'
import { CircularProgress, Grid, Typography } from 'components'
import { RadioGroup } from 'components/Form/RadioGroup'
import { theme } from 'providers/Theme'
import { ImportOptionsProps } from './interfaces'

const ImportOptions: FC<ImportOptionsProps> = ({
  option,
  setOption,
  selectedCompany,
  situations
}) => {
  const {
    data: { add = 0, found = 0, remove = 0, totalSheet = 0 },
    situationsLoading
  } = situations

  const changeOptionToImport = ({ target }) => {
    setOption(target.value)
  }

  const renderLabel = (title: string, subtitle: string) => (
    <>
      <Typography variant="body1" color={theme.palette.colors.neutral[700]}>
        {title}
      </Typography>
      <Typography variant="body1" color={theme.palette.colors.neutral[500]}>
        {subtitle}
      </Typography>
    </>
  )

  const renderLoading = (
    <Grid container className="fullWidth" justifyContent="center" spacing={2}>
      <CircularProgress size={32} className="m-4" />
    </Grid>
  )

  const renderOptions = (
    <Grid container direction="column" className="fullHeight">
      <Grid item className="fullWidth p-2" xs>
        <Grid container className="fullHeight" justifyContent="center" spacing={2}>
          <Typography variant="body3" color={theme.palette.colors.neutral[500]}>
            {CNPJUtils.format(selectedCompany.cnpj)} - {selectedCompany.tradeName}
          </Typography>
          <RadioGroup
            name="option"
            value={option || ''}
            onChange={changeOptionToImport}
            options={[
              {
                value: 'add',
                label: renderLabel(
                  i18n.t('employee.userImport.addEmployee'),
                  i18n.t('employee.userImport.addEmployeeSubtitle', {
                    add,
                    found,
                    totalSheet: totalSheet - found - add
                  })
                )
              },
              {
                value: 'replace',
                label: renderLabel(
                  i18n.t('employee.userImport.replaceEmployee'),
                  i18n.t('employee.userImport.replaceEmployeeSubtitle', {
                    found,
                    add,
                    remove
                  })
                )
              },
              {
                value: 'remove',
                label: renderLabel(
                  i18n.t('employee.userImport.removeEmployee'),
                  i18n.t('employee.userImport.removeEmployeeSubtitle', {
                    found,
                    totalSheet: totalSheet - found
                  })
                )
              }
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  )

  return situationsLoading ? renderLoading : renderOptions
}

export default memo(ImportOptions)
